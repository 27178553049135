import React from "react";
import { Link } from "react-router-dom";
import AuthModal from "../modals/AuthModal";
import ContactModal from "../modals/ContactModal";
function Terms() {
  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12">
                  <nav className="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES{" "}
                        <span className="badge topbadge themebg">4</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES{" "}
                        <span className="badge topbadge themebg">0</span>
                      </a>
                    </li>
                    <li>
                      {/* <a href="#login-popup" className="open-popup-link">
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp; Login{" "}
                      </a> */}
                      <AuthModal />
                    </li>
                  </nav>

                  <nav className="main-menu term-head text-left">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </nav>
                </figure>
              </figure>
              <figure className="row">
                <div className="bs-example" style={{paddingLeft:0}}>
                  <figure className="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    className="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="fa fa-bars text-white"></span>
                    </button>
                  </nav>

                  <div
                    className="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Va Loan</Link>
                    </li>
                    <li>
                      <Link to="/">Relocation</Link>
                    </li>
                    <li>
                      <Link to="/">Resources</Link>
                    </li>
                    <li>
                      <Link to="/">Advertise</Link>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </div>
                </div>
                <figure className="col-md-3  logoback">
                  <Link to="/">
                    <img className="logo" src="images/logo5.png" alt="" />
                  </Link>
                </figure>
                <figure className="offset-md-2 col-md-7 searching">
                  <div className="row justify-content-right">
                    <div className=" col-md-12 text-right input-group md-form form-sm form-2 pl-0">
                      <input
                        className="form-control my-0 py-1 red-border"
                        type="text"
                        placeholder="Search Properties by Military Installation, Address, City, County, Zip Code or MLS #"
                        aria-label="Search"
                        style={{ fontSize: "17px" }}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text red lighten-3"
                          id="basic-text1"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </figure>
              </figure>
            </article>
          </section>
        </header>

        <article id="term-section">
          <section className="container-fluid">
            <figure className="row">
              <figure className="col-md-12">
                <h1>
                  ACCEPTANCE <span>OF TERMS</span>
                </h1>
                <p>
                  Military housing center provides a collection of online
                  resources, including classified ads, forums, catalogs, and
                  various email services available on or through military
                  housing center (referred to hereafter as “the Service”)
                  subject to the following Terms of Use (“TOU”). By using the
                  Service in any way, you are agreeing to comply with the TOU.
                  In addition, when using particular military housing center
                  services, you agree to abide by any applicable posted
                  guidelines for all military housing center services, which may
                  change from time to time. Should you object to any term or
                  condition of the TOU, any guidelines, or any subsequent
                  modifications thereto or become dissatisfied with the Service
                  in any way, your only recourse is to immediately discontinue
                  use of the Service.
                </p>

                <h1>
                  MODIFICATIONS TO <span>THIS AGREEMENT</span>
                </h1>
                <p>
                  We reserve the right, at our sole discretion, to change,
                  modify or otherwise alter these terms and conditions at any
                  time. Such modifications shall become effective immediately
                  upon the posting thereof. You must review this agreement on a
                  regular basis to keep yourself apprised of any changes.
                </p>
                <h3>ELIGIBILITY FOR USE</h3>

                <p>
                  Military housing center reserves some parts of the Service
                  exclusively for professional purchasers, sellers, collectors
                  and resellers of items and accessories who are acceptable to
                  military housing center in its sole discretion. Although
                  military housing center attempts to limit the use of these
                  parts of the Service to such purchasers, sellers, collectors
                  and resellers of items and accessories, nothing herein shall
                  create any right of action against military housing center for
                  failing to adequately screen potential users or prevent the
                  use of military housing center or the Service by users who are
                  not professional purchasers, sellers, collectors and resellers
                  of items or accessories.
                </p>

                <h1>CONTENT</h1>
                <p>
                  You understand that all advertisements, postings, messages,
                  text, files, images, photos, video, sounds, or other materials
                  (“Content”) posted on, transmitted through, or linked from the
                  Service are the sole responsibility of the person from whom
                  such Content originated. More specifically, you are entirely
                  responsible for each individual item of Content that you post,
                  email or otherwise make available via the Service. Likewise,
                  if you request the assistance of military housing center to
                  post content, or if you imply consent that military housing
                  center may post content on your behalf, either by written or
                  oral means, or you fail to inform military housing center that
                  content should be removed, you are equally responsible for the
                  content. You waive any and all claims against military housing
                  center of a right of publicity for your image or likeness
                  throughout the world by posting any information or pictures of
                  yourself on military housing center. You understand that
                  military housing center does not control, and is not
                  responsible for personal Content made available through the
                  Service, and that by using the Service, you may be exposed to
                  Content that is offensive, indecent, inaccurate, misleading,
                  or otherwise objectionable. When you post User Content to the
                  Service, you authorize and direct us to make such copies
                  thereof as we deem necessary in order to facilitate the
                  posting and storage of the Content on the Service. By posting
                  Content to any part of the Service, you automatically grant,
                  and you represent and warrant that you have the right to
                  grant, to the Company an irrevocable, perpetual,
                  non-exclusive, transferable, fully paid, worldwide license
                  (with the right to sublicense) to use, copy, publicly perform,
                  publicly display, reformat, translate, excerpt (in whole or in
                  part) and distribute such Content for any purpose on or in
                  connection with the Service or the promotion thereof, to
                  prepare derivative works of, or incorporate into other works,
                  such Content, and to grant and authorize sublicenses of the
                  foregoing. Furthermore, military housing center and Content
                  available through the Service may contain links to other
                  websites, which are completely independent of military housing
                  center. military housing center makes no representation or
                  warranty as to the accuracy, completeness or authenticity of
                  the information contained in any such site. Following links to
                  any other websites is at your own risk. You agree that you
                  must evaluate, and bear all risks associated with, the use of
                  any Content, that you may not rely on said Content, and that
                  under no circumstances will military housing center be liable
                  in any way for any Content or for any loss or damage of any
                  kind incurred as a result of the use of any Content posted,
                  emailed or otherwise made available via the Service. You
                  acknowledge that military housing center does not pre-screen
                  or approve Content, but that military housing center shall
                  have the right (but not the obligation) in its sole discretion
                  to refuse, delete or move any Content that is available via
                  the Service, for violating the letter or spirit of the TOU or
                  for any other reason.
                </p>

                <h1>
                  THIRD PARTY CONTENT, <span>SITES, AND SERVICES</span>
                </h1>
                <p>
                  Military housing center and Content available through the
                  Service may contain features and functionalities that may link
                  you or provide you with access to third party content which is
                  completely independent of military housing center, including
                  web sites, directories, servers, networks, systems,
                  information and databases, applications, software, programs,
                  products or services, and the Internet as a whole. Your
                  interactions with organizations and/or individuals found on or
                  through the Service, including payment and delivery of goods
                  or services, and any other terms, conditions, warranties or
                  representations associated with such dealings, are solely
                  between you and such organizations and/or individuals. You
                  should make whatever investigation you feel necessary or
                  appropriate before proceeding with any online or offline
                  transaction with any of these third parties. We do not
                  guarantee the quality, safety or legality of, any Content, the
                  truth or accuracy of the descriptions of any goods or services
                  offered for sale, the right of the sellers to sell or license
                  any such goods or services, or the ability of any buyer to
                  purchase any such goods or services. The Service is designed
                  for experienced buyers accustomed to buying goods and services
                  based on photographs and/or descriptive text. Buyers should
                  assume that any goods offered are not new, unless otherwise
                  stated, nor in perfect condition, and may require touch-up or
                  repairs prior to use and that the available information about
                  the items may be limited. It is not possible for military
                  housing center to verify information provided by the seller of
                  any item.
                </p>

                <p>
                  You agree that military housing center shall not be
                  responsible or liable for any loss or damage of any sort
                  incurred as the result of any dealings between users of the
                  Service. If there is a dispute between users of the Service,
                  or between users and any third party, you understand and agree
                  that military housing center is under no obligation to become
                  involved. In the event that you have a dispute with one or
                  more other users, you hereby forever release military housing
                  center, its officers, employees, agents and successors in
                  rights from claims, demands and damages (actual and
                  consequential) of every kind or nature, known or unknown,
                  suspected and unsuspected, disclosed and undisclosed, arising
                  out of or in any way related to such disputes and/or our
                  service.
                </p>

                <h1>
                  NOTIFICATION OF CLAIMS <span>OF INFRINGEMENT</span>
                </h1>
                <p>
                  If you believe that your work has been copied in a way that
                  constitutes copyright infringement, or your intellectual
                  property rights have been otherwise violated, please send your
                  notice (“Notice”) to military housing center’s agent for
                  notice of claims of copyright or other intellectual property
                  infringement:
                </p>

                <p>by email: notice@payrex.com</p>
              </figure>
            </figure>
          </section>
        </article>
      </article>

      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/terms">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/policy">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/terms">Business Directory</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/terms">About Us</Link>
                </li>
                <li>
                  <Link to="/terms">Careers</Link>
                </li>
                <li>
                  {/* <a href="#contact-popup" className="open-popup-link"> */}
                  <ContactModal />
                  {/* </a> */}
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info" alt="">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default Terms;
