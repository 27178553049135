import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
import AuthModal from "../modals/AuthModal";
import useScrollPosition from "./useScrollPosition";
function FairHousing() {
  const scrollPosition = useScrollPosition();
  const [isNavVisible, setIsNavVisible] = useState(true);

  useEffect(() => {
    setIsNavVisible(scrollPosition < 50); // Adjust the threshold as needed
  }, [scrollPosition]);
  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12">
                  <nav className="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES{" "}
                        <span className="badge topbadge themebg">4</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES{" "}
                        <span className="badge topbadge themebg">0</span>
                      </a>
                    </li>
                    <li>
                      <AuthModal />
                    </li>
                  </nav>

                  <nav className="main-menu term-head text-left">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </nav>
                </figure>
              </figure>
             <figure className="row" >
                <div className="bs-example" style={{ paddingLeft: 0 }}>
                  <figure className="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    className="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="fa fa-bars text-white"></span>
                    </button>
                  </nav>

                  <div
                    className="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Va Loan</Link>
                    </li>
                    <li>
                      <Link to="/">Relocation</Link>
                    </li>
                    <li>
                      <Link to="/">Resources</Link>
                    </li>
                    <li>
                      <Link to="/">Advertise</Link>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </div>
                </div>
                <figure className="col-md-3  logoback">
                  <Link to="/">
                    <img className="logo" src="images/logo5.png" alt="" />
                  </Link>
                </figure>
               
                  <figure className="offset-md-2 col-md-7 searching">
                    <div className="row justify-content-right">
                      <div className=" col-md-12 text-right input-group md-form form-sm form-2 pl-0">
                        <input
                          className="form-control my-0 py-1 red-border"
                          type="text"
                          placeholder="Search Properties by Military Installation, Address, City, County, Zip Code or MLS #"
                          aria-label="Search"
                          style={{ fontSize: "17px" }}
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text red lighten-3"
                            id="basic-text1"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </figure>
              
              </figure>
             
            </article>
          </section>
        </header>

        <article id="term-section">
          <section class="container-fluid">
            <figure class="row">
              <figure class="col-md-12">
                <h1>
                  FAIR <span>HOUSING POLICY</span>
                </h1>
                <p>
                  All real estate advertised herein is subject to the federal
                  Fair Housing Act, which makes it illegal to advertise "any
                  preference, limitation, or discrimination because of race,
                  color, religion, sex, handicap, familial status, or national
                  origin, or intention to make such preference, limitation or
                  discrimination." We will not knowingly accept or permit any
                  advertisement for real estate that is in violation of the law.
                  All persons are hereby informed that all dwellings advertised
                  are available on an equal opportunity basis.
                </p>

                <h1>
                  THE <span>LAW</span>
                </h1>
                <p>
                  When it comes to buying, selling or leasing a home or renting
                  an apartment, several state and federal laws were enacted to
                  ensure the equal opportunity in housing for all people. The
                  Civil Rights Act of 1966 prohibits all racial discrimination
                  in the sale or rental of property. Moreover, the Fair Housing
                  Act declares a national policy of fair housing throughout the
                  United States, making illegal any discrimination in the sale,
                  lease or rental of housing, or making housing otherwise
                  unavailable, because of race, color, religion, sex, handicap,
                  familial status, or national origin. Other laws also impact
                  the purchase, sale or lease of a home or renting an apartment.
                  Title III of the Americans with Disabilities Act prohibits
                  discrimination against persons with disabilities in places of
                  public accommodations and commercial facilities. The Equal
                  Credit Opportunity Act makes discrimination unlawful with
                  respect to any aspect of a credit application on the basis of
                  race, color, religion, national origin, sex, marital status,
                  age or because all or part of the applicant’s income derives
                  from any public assistance program In addition, state and
                  local laws often provide broader coverage and prohibit
                  discrimination based on additional classes not covered by
                  federal law. As a home seller or landlord you have a
                  responsibility and a requirement under the law not to
                  discriminate in the sale, rental and financing of property on
                  the basis of race, color, religion, sex, handicap, familial
                  status, or national origin. You cannot instruct the licensed
                  broker or salesperson acting as your agent to convey for you
                  any limitations in the sale or rental because the real estate
                  professional is bound by law not to discriminate. Agents in a
                  real estate transaction also are prohibited by law from
                  discriminating on the basis of race, color, religion, sex,
                  handicap, familial status, or national origin. They are
                  prohibited from complying with a request from the home seller
                  or landlord to act in a discriminatory manner in the sale,
                  lease or rental. Moreover, a home seller or landlord cannot
                  establish discriminatory terms or conditions in the purchase
                  or rental, deny that housing is available, or advertise that
                  the property is available only to persons of a certain race,
                  color, religion, sex, handicap, familial status, or national
                  original. As someone seeking to purchase a home or rent an
                  apartment, you have the right to expect that housing will be
                  available to you without discrimination or other limitations
                  based on race, color, religion, sex, handicap, familial
                  status, or national origin. This includes the right to expect
                  equal professional service, the opportunity to consider a
                  broad range of housing choices, no discriminatory limitations
                  on communities or locations of housing, no discrimination in
                  the financing, appraising, or insuring of housing, reasonable
                  accommodations in rules, practices and procedures for persons
                  with disabilities, and to be free from harassment or
                  intimidation for exercising your fair housing rights.
                </p>

                <h1>
                  IF YOU SUSPECT <span>DISCRIMINATION:</span>
                </h1>
                <p>
                  Despite our zero tolerance policy, much of the information
                  regarding real estate and related matters in this Web Site is
                  provided by third parties or via hyperlink to third party
                  sites. As such, there may be an instance of potential
                  discrimination by these third parties that we are not yet
                  aware of. If you suspect discrimination by one of these sites,
                  please contact our staff at our main offices at: Florida Dream
                  Team Realty Services, LLC 1956-1958 Main Street Sarasota, FL
                  34236 or call us at: (941) 827-070 Complaints alleging
                  discrimination in housing may be filed with the nearest office
                  of the United States Department of Housing and Urban
                  Development (HUD), or by calling HUD’s toll free number,
                  1-800-669-9777 (voice), or 1-800-543-8294 (TDD). You can
                  contact HUD on the internet at
                  http://www.hud.gov/complaints/index.cfm
                </p>

                <div class="fair-btn text-center">
                  <a href="/">
                    <img src="images/fair-icon.jpg" alt="" />
                  </a>
                </div>
              </figure>
            </figure>
          </section>
        </article>
      </article>

      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/fairHousing">Business Directory</Link>
                </li>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/fairHousing">About Us</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Careers</Link>
                </li>
                <li>
                  <ContactModal />
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info" alt="">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default FairHousing;
