import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
import AuthModal from "../modals/AuthModal";
import PropertyCard from "../card/PropertyCard";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
function SearchResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState([]);
  const [error, setError] = useState(null);
  const [propertyCount, setPropertyCount] = useState(0);
  const [search, setSearch] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [selectedSort, setSelectedSort] = useState(
    new URLSearchParams(location.search).get("sort") || "forsale"
  );
  const [beds, setBeds] = useState(""); // State to hold the number of beds
  const [baths, setBaths] = useState(""); // State to hold the number of beds
  const [hoaFee, setHoaFee] = useState("");
  const [openHouseChecked, setOpenHouseChecked] = useState(false);
  const [threeDChecked, setThreeDChecked] = useState(false);
  const [largeDogsChecked, setLargeDogsChecked] = useState(false);
  const [catsChecked, setCatsChecked] = useState(false);
  const [smallDogsChecked, setSmallDogsChecked] = useState(false);
  const [acChecked, setAcChecked] = useState(false);
  const [laundryChecked, setLaundryChecked] = useState(false);
  const [poolChecked, setPoolChecked] = useState(false);
  const [mhcAppChecked, setMhcAppChecked] = useState(false);
  const [waterfrontChecked, setWaterfrontChecked] = useState(false);
  const [incomeRestrictedChecked, setIncomeRestrictedChecked] = useState(false);
  const [onsiteParkingChecked, setOnsiteParkingChecked] = useState(false);
  const [viewCityChecked, setViewCityChecked] = useState(false);
  const [viewParkChecked, setViewParkChecked] = useState(false);
  const [viewMountainChecked, setViewMountainChecked] = useState(false);
  const [viewWaterChecked, setViewWaterChecked] = useState(false);
  const [selectedParkingSpots, setSelectedParkingSpots] = useState("");
  const [hasGarageChecked, setHasGarageChecked] = useState(false);
  const [basement, setBasement] = useState(false);
  const [story, setStory] = useState(false);
  const [mhcOwned, setMhcOwned] = useState(false);
  const [mhcDays, setMhcDays] = useState("");
  const [selected, setSelected] = useState([]);
  // Handle increment and decrement button clicks
  const [price, setPrice] = useState({ min: "", max: "" });
  const [isMinNext, setIsMinNext] = useState(true); // true if next click should set Min
  const [maxSquare, setMaxSquare] = useState("");
  const [minSquare, setMinSquare] = useState("");
  const [minLotSize, setMinLotSize] = useState("");
  const [maxLotSize, setMaxLotSize] = useState("");
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [keywords, setKeywords] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [progress, setProgress] = useState(0);
  const [headerText, setHeaderText] = useState("Found");
  const [visibleCount, setVisibleCount] = useState(4);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null); // Reference for the loader element
  const fetchTimeout = useRef(null);

  useEffect(() => {
    let apiUrl = "https://backend.tanzee.pk/api/property/types";

    if (selectedSort === "forrent") {
      apiUrl = "https://backend.tanzee.pk/api/property/types/rents";
    } else if (selectedSort === "forsale") {
      apiUrl = "https://backend.tanzee.pk/api/property/types/sales";
    }

    axios
      .get(apiUrl)
      .then((response) => {
        const propertyTypes = response.data;
        setCard(propertyTypes);
        const params = new URLSearchParams(location.search);
        const selectedFromURL = params.get("selected");
        if (selectedFromURL) {
          setSelected(selectedFromURL.split(","));
        } else {
          setSelected(propertyTypes.map((type) => type.property_type));
        }
      })
      .catch((error) => {
        console.log("there is an error", error);
      });
  }, [selectedSort]);
  useEffect(() => {
    axios
      .get("https://backend.tanzee.pk/api/property/all")
      .then((response) => {
        setCard1(response.data);
      })
      .catch((error) => {
        console.error("there is an error", error);
      });
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          // Clear any previous timeout to avoid multiple triggers
          if (fetchTimeout.current) {
            clearTimeout(fetchTimeout.current);
          }

          // Set a timeout to delay the loading of more cards
          fetchTimeout.current = setTimeout(() => {
            setVisibleCount((prevCount) => prevCount + 4);
          }, 1000); // 2-second delay
        }
      },
      { threshold: 1.0 }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
      if (fetchTimeout.current) {
        clearTimeout(fetchTimeout.current);
      }
    };
  }, [hasMore]);

  const updateURL = () => {
    const params = new URLSearchParams();
    if (search) params.set("search", search);
    if (selectedSort) params.set("sort", selectedSort);
    if (selected.length > 0) {
      params.set("selected", selected.join(","));
    } else {
      params.delete("selected");
    }
    if (beds) params.set("beds", beds);
    if (baths) params.set("baths", baths);
    if (hoaFee) params.set("hoaFee", hoaFee);
    if (openHouseChecked) params.set("openHouse", openHouseChecked);
    if (threeDChecked) params.set("threeD", threeDChecked);
    if (largeDogsChecked) params.set("largeDogs", largeDogsChecked);
    if (catsChecked) params.set("cats", catsChecked);
    if (smallDogsChecked) params.set("smallDogs", smallDogsChecked);
    if (acChecked) params.set("ac", acChecked);
    if (laundryChecked) params.set("laundry", laundryChecked);
    if (poolChecked) params.set("pool", poolChecked);
    if (mhcAppChecked) params.set("mhcApp", mhcAppChecked);
    if (waterfrontChecked) params.set("waterfront", waterfrontChecked);
    if (incomeRestrictedChecked)
      params.set("incomeRestricted", incomeRestrictedChecked);
    if (onsiteParkingChecked) params.set("onsiteParking", onsiteParkingChecked);
    if (viewCityChecked) params.set("viewCity", viewCityChecked);
    if (viewParkChecked) params.set("viewPark", viewParkChecked);
    if (viewMountainChecked) params.set("viewMountain", viewMountainChecked);
    if (viewWaterChecked) params.set("viewWater", viewWaterChecked);
    if (selectedParkingSpots) params.set("parkingSpots", selectedParkingSpots);
    if (hasGarageChecked) params.set("garage", hasGarageChecked);
    if (basement) params.set("basement", basement);
    if (story) params.set("story", story);
    if (mhcOwned) params.set("mhcOwned", mhcOwned);
    if (mhcDays) params.set("mhcDays", mhcDays);
    if (price.min) params.set("priceMin", price.min);
    if (price.max) params.set("priceMax", price.max);
    if (minSquare) params.set("minSquare", minSquare);
    if (maxSquare) params.set("maxSquare", maxSquare);
    if (minLotSize) params.set("minLotSize", minLotSize);
    if (maxLotSize) params.set("maxLotSize", maxLotSize);
    if (minYear) params.set("minYear", minYear);
    if (maxYear) params.set("maxYear", maxYear);
    if (keywords) params.set("keywords", keywords);

    navigate(`${location.pathname}?${params.toString()}`);
  };

  useEffect(updateURL, [
    search,
    selectedSort,
    selected,
    beds,
    baths,
    hoaFee,
    openHouseChecked,
    threeDChecked,
    largeDogsChecked,
    catsChecked,
    smallDogsChecked,
    acChecked,
    laundryChecked,
    poolChecked,
    mhcAppChecked,
    waterfrontChecked,
    incomeRestrictedChecked,
    onsiteParkingChecked,
    viewCityChecked,
    viewParkChecked,
    viewMountainChecked,
    viewWaterChecked,
    selectedParkingSpots,
    hasGarageChecked,
    basement,
    story,
    mhcOwned,
    mhcDays,
    price,
    minSquare,
    maxSquare,
    minLotSize,
    maxLotSize,
    minYear,
    maxYear,
    keywords,
  ]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    if (searchQuery) {
      setSearch(searchQuery);
      setShowFilter(true);
    }
    const sort = params.get("sort");
    if (sort) setSelectedSort(sort);
    // const selectedFromURL = params.get("selected");
    // if (selectedFromURL) setSelected(selectedFromURL.split(","));

    const bedsParam = params.get("beds");
    if (bedsParam) setBeds(bedsParam);

    const bathsParam = params.get("baths");
    if (bathsParam) setBaths(bathsParam);

    const hoaFeeParam = params.get("hoaFee");
    if (hoaFeeParam) setHoaFee(hoaFeeParam);

    const OpenHouseParam = params.get("openHouse");
    if (OpenHouseParam) setOpenHouseChecked(OpenHouseParam);

    const threeDParam = params.get("threeD");
    if (threeDParam) setThreeDChecked(threeDParam);

    const largeDogsParam = params.get("largeDogs");
    if (largeDogsParam) setLargeDogsChecked(largeDogsParam);

    const cats = params.get("cats");
    if (cats) setCatsChecked(cats === "true");

    const smallDogs = params.get("smallDogs");
    if (smallDogs) setSmallDogsChecked(smallDogs === "true");

    const ac = params.get("ac");
    if (ac) setAcChecked(ac === "true");

    const laundry = params.get("laundry");
    if (laundry) setLaundryChecked(laundry === "true");

    const pool = params.get("pool");
    if (pool) setPoolChecked(pool === "true");

    const mhcApp = params.get("mhcApp");
    if (mhcApp) setMhcAppChecked(mhcApp === "true");

    const waterfront = params.get("waterfront");
    if (waterfront) setWaterfrontChecked(waterfront === "true");

    const incomeRestricted = params.get("incomeRestricted");
    if (incomeRestricted)
      setIncomeRestrictedChecked(incomeRestricted === "true");

    const onsiteParking = params.get("onsiteParking");
    if (onsiteParking) setOnsiteParkingChecked(onsiteParking === "true");

    const viewCity = params.get("viewCity");
    if (viewCity) setViewCityChecked(viewCity === "true");

    const viewPark = params.get("viewPark");
    if (viewPark) setViewParkChecked(viewPark === "true");

    const viewMountain = params.get("viewMountain");
    if (viewMountain) setViewMountainChecked(viewMountain === "true");

    const viewWater = params.get("viewWater");
    if (viewWater) setViewWaterChecked(viewWater === "true");

    const garage = params.get("garage");
    if (garage) setHasGarageChecked(garage === "true");

    const basement = params.get("basement");
    if (basement) setBasement(basement === "true");

    const story = params.get("story");
    if (story) setStory(story === "true");

    const mhcOwned = params.get("mhcOwned");
    if (mhcOwned) setMhcOwned(mhcOwned === "true");

    const parkingSpots = params.get("parkingSpots");
    if (parkingSpots) setSelectedParkingSpots(parkingSpots);

    const mhcDays = params.get("mhcDays");
    if (mhcDays) setMhcDays(mhcDays);

    const priceMin = params.get("priceMin");
    const priceMax = params.get("priceMax");
    if (priceMin || priceMax)
      setPrice({ min: priceMin || "", max: priceMax || "" });

    const minSquare = params.get("minSquare");
    if (minSquare) setMinSquare(minSquare);

    const maxSquare = params.get("maxSquare");
    if (maxSquare) setMaxSquare(maxSquare);

    const minLotSize = params.get("minLotSize");
    if (minLotSize) setMinLotSize(minLotSize);

    const maxLotSize = params.get("maxLotSize");
    if (maxLotSize) setMaxLotSize(maxLotSize);

    const minYear = params.get("minYear");
    if (minYear) setMinYear(minYear);

    const maxYear = params.get("maxYear");
    if (maxYear) setMaxYear(maxYear);

    const keywords = params.get("keywords");
    if (keywords) setKeywords(keywords);
  }, [location.search]);

  const handleCheckboxChange = (type) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(type)) {
        return prevSelected.filter((t) => t !== type);
      } else {
        return [...prevSelected, type];
      }
    });
  };

  const handlePriceClick = (value) => {
    if (isMinNext) {
      setPrice({ ...price, min: value });
    } else {
      setPrice({ ...price, max: value });
    }
    setIsMinNext(!isMinNext); // Toggle to target the next input
  };
  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    setShowFilter(newSearch !== "");
    if (newSearch.includes(",")) {
      const [city, state] = newSearch.split(",").map((s) => s.trim());
      // setHeaderText(` in ${city}${state ? `, ${state}` : ""}`);
      setHeaderText(
        <>
          <span style={{ color: "#c70000" }}></span> in {city}
          {state ? `, ${state}` : ""}
        </>
      );
    } else if (newSearch) {
      if (
        card1.some(
          (agent) =>
            agent.city.toLowerCase().includes(newSearch.toLowerCase()) ||
            agent.state.toLowerCase().includes(newSearch.toLowerCase())
        )
      ) {
        setHeaderText(` in ${newSearch}`);
      } else {
        setHeaderText(`in ${newSearch}`);
      }
    } else {
      setHeaderText("Found");
    }
  };
  const clearFilter = () => {
    setSearch("");
    setShowFilter(false);
  };
  const removeTag = (type) => {
    setSelected((prevSelected) => prevSelected.filter((t) => t !== type));
  };
  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };
  const removePrice = (type) => {
    if (type === "min") {
      setPrice({ ...price, min: "" });
    } else if (type === "max") {
      setPrice({ ...price, max: "" });
    }
    setShowFilter(false);
  };
  const updateBeds = (operation) => {
    setBeds((prevBeds) => {
      const newBeds =
        operation === "add"
          ? Math.min(+prevBeds + 1, 10)
          : Math.max(+prevBeds - 1, 1);
      return newBeds;
    });
  };

  // Handle input change
  const handleBedInputChange = (e) => {
    setBeds(e.target.value);
  };
  const clearBed = () => {
    setShowFilter(false);
    setBeds("");
  };
  const updateBaths = (operation) => {
    setBaths((prevBaths) => {
      const newBaths =
        operation === "add"
          ? Math.min(+prevBaths + 0.5, 5)
          : Math.max(+prevBaths - 0.5, 1);
      return newBaths;
    });
  };

  // Handle input change
  const handleBathInputChange = (e) => {
    setBaths(e.target.value);
  };
  const handleHoaChange = (e) => {
    setHoaFee(e.target.value);
    // Handle other dropdown changes similarly
  };
  const handleMoreChange = (event) => {
    const { name, checked } = event.target;
    if (name === "openhouse") {
      setOpenHouseChecked(checked);
    } else if (name === "3dhouse") {
      setThreeDChecked(checked);
    } else if (name === "petsldogs") {
      setLargeDogsChecked(checked);
    } else if (name === "petscats") {
      setCatsChecked(checked);
    } else if (name === "petssdos") {
      setSmallDogsChecked(checked);
    } else if (name === "ameac") {
      setAcChecked(checked);
    } else if (name === "ameinunit") {
      setLaundryChecked(checked);
    } else if (name === "amepool") {
      setPoolChecked(checked);
    } else if (name === "ameinmhcapp") {
      setMhcAppChecked(checked);
    } else if (name === "amewaterfront") {
      setWaterfrontChecked(checked);
    } else if (name === "ameinincomeres") {
      setIncomeRestrictedChecked(checked);
    } else if (name === "ameinonsitep") {
      setOnsiteParkingChecked(checked);
    } else if (name === "viewcity") {
      setViewCityChecked(checked);
    } else if (name === "viewpark") {
      setViewParkChecked(checked);
    } else if (name === "viewmountain") {
      setViewMountainChecked(checked);
    } else if (name === "viewwater") {
      setViewWaterChecked(checked);
    } else if (name === "hasgarage") {
      setHasGarageChecked(checked);
    } else if (name === "basement") {
      setBasement(checked);
    } else if (name === "noofstories") {
      setStory(checked);
    } else if (name === "mhcowned") {
      setMhcOwned(checked);
    }
  };
  const handleParkingSpotsChange = (event) => {
    setSelectedParkingSpots(event.target.value);
  };
  const handleMhcDaysChange = (event) => {
    setMhcDays(event.target.value);
  };
  const handleMaxSquareChange = (event) => {
    setMaxSquare(event.target.value);
  };
  const handleMinSquareChange = (event) => {
    setMinSquare(event.target.value);
  };
  const handleMinLotSizeChange = (event) => {
    setMinLotSize(event.target.value);
  };
  const handleMaxLotSizeChange = (event) => {
    setMaxLotSize(event.target.value);
  };
  const handleMinYear = (event) => {
    setMinYear(event.target.value);
  };
  const handleMaxYear = (event) => {
    setMaxYear(event.target.value);
  };
  const handleKeywords = (e) => {
    setKeywords(e.target.value);
  };
  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
  };
  const clearSortFilter = () => {
    setSelectedSort(null);
    setShowFilter(false);
  };
  const clearBath = () => {
    setShowFilter(false);
    setBaths("");
  };
  const clearHoaFilter = (e) => {
    setShowFilter(false);
    setHoaFee("");
  };
  const clearOpenHouse = (e) => {
    setShowFilter(false);
    setOpenHouseChecked(false);
  };
  const clearThreed = (e) => {
    setShowFilter(false);
    setThreeDChecked(false);
  };
  const clearParkingSpot = (e) => {
    setShowFilter(false);
    setSelectedParkingSpots("");
  };
  const clearHaveGarage = (e) => {
    setShowFilter(false);
    setHasGarageChecked(false);
  };
  const clearBasement = (e) => {
    setShowFilter(false);
    setBasement(false);
  };
  const clearStories = (e) => {
    setShowFilter(false);
    setStory(false);
  };
  const clearLargeDogs = (e) => {
    setShowFilter(false);
    setLargeDogsChecked(false);
  };
  const clearSmalleDogs = (e) => {
    setShowFilter(false);
    setSmallDogsChecked(false);
  };
  const clearCats = (e) => {
    setShowFilter(false);
    setCatsChecked(false);
  };
  const clearAc = (e) => {
    setShowFilter(false);
    setAcChecked(false);
  };
  const clearLaundary = (e) => {
    setShowFilter(false);
    setLaundryChecked(false);
  };
  const clearPool = (e) => {
    setShowFilter(false);
    setPoolChecked(false);
  };
  const clearMhcApplication = (e) => {
    setShowFilter(false);
    setMhcAppChecked(false);
  };
  const clearWaterfront = (e) => {
    setShowFilter(false);
    setWaterfrontChecked(false);
  };
  const clearIncomeRestricted = (e) => {
    setShowFilter(false);
    setIncomeRestrictedChecked(false);
  };
  const clearOnSitePArking = (e) => {
    setShowFilter(false);
    setOnsiteParkingChecked(false);
  };
  const clearCity = (e) => {
    setShowFilter(false);
    setViewCityChecked(false);
  };
  const clearPark = (e) => {
    setShowFilter(false);
    setViewParkChecked(false);
  };
  const clearMountain = (e) => {
    setShowFilter(false);
    setViewMountainChecked(false);
  };
  const clearWater = (e) => {
    setShowFilter(false);
    setViewWaterChecked(false);
  };
  const clearMhcOwned = (e) => {
    setShowFilter(false);
    setMhcOwned(false);
  };
  const clearMinSquare = (e) => {
    setShowFilter(false);
    setMinSquare("");
  };
  const clearMaxSquare = (e) => {
    setShowFilter(false);
    setMaxSquare("");
  };
  const clearMinLotSize = (e) => {
    setShowFilter(false);
    setMinLotSize("");
  };
  const clearMaxLotSize = (e) => {
    setShowFilter(false);
    setMaxLotSize("");
  };
  const clearMaxYear = (e) => {
    setShowFilter(false);
    setMaxYear("");
  };
  const clearMinYear = (e) => {
    setShowFilter(false);
    setMinYear("");
  };
  const clearDaysOnMhc = (e) => {
    setShowFilter(false);
    setMhcDays("");
  };
  const clearKeywords = (e) => {
    setShowFilter(false);
    setKeywords("");
  };

  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12">
                  <nav className="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES{" "}
                        <span className="badge topbadge themebg">4</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES{" "}
                        <span className="badge topbadge themebg">0</span>
                      </a>
                    </li>
                    <li>
                      <AuthModal />
                    </li>
                    <LoadingBar progress={progress} color="red" />
                  </nav>

                  <nav className="main-menu term-head text-left">
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <Link to="/AgentSearchResult">Find an Agent</Link>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </nav>
                </figure>
              </figure>
              <figure className="row">
                <div className="bs-example" style={{ paddingLeft: 0 }}>
                  <figure className="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    className="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="fa fa-bars text-white"></span>
                    </button>
                  </nav>

                  <div
                    className="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </div>
                </div>
                <figure className="col-md-3 logoback">
                  <Link to="/">
                    <img alt="" className="logo" src="images/logo5.png" />
                  </Link>
                </figure>
                <figure className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                  <div className="row searchFilter">
                    <div className="col-sm-12">
                      <div className="input-group">
                        <input
                          id="table_filter"
                          type="text"
                          className="form-control searchbar_property"
                          placeholder="Anchorage, AK"
                          aria-label="Text input with segmented button dropdown"
                          onChange={handleSearchChange}
                          value={search}
                        />
                        <div
                          className="input-group-append mr-1"
                          style={{ marginLeft: "-.75rem" }}
                        >
                          <span
                            className="input-group-text red lighten-3"
                            id="basic-text1"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </div>
                        <div className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            fdprocessedid="8h6trl"
                          >
                            <span className="label-icon">Type</span>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-right hometypefilter"
                            onClick={handleDropdownClick}
                          >
                            <div className="p-2">
                              <h5 className="mb-3 mt-1">Property Type</h5>
                              <ul className="category_filters pb-2 border-bottom">
                                {card.map((data, index) => (
                                  <li key={index}>
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input checkbox filter "
                                        id={data.property_type}
                                        name="ptype[]"
                                        value={data.property_type}
                                        checked={selected.includes(
                                          data.property_type
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            data.property_type
                                          )
                                        }
                                      />
                                      <label
                                        className="custom-control-label text-dark text-dark p-0  d-block filter"
                                        htmlFor={data.property_type}
                                      >
                                        {data.property_type}{" "}
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "smaller" }}
                                          id="counthouse"
                                        >
                                          ({data.property_count})
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>

                              <h5 className="mb-2 mt-2">Sort</h5>
                              <ul className="category_filters">
                                <li>
                                  <input
                                    className="cat_type category-input filtersearchtype"
                                    data-label="All"
                                    id="allrec"
                                    name="pfor"
                                    type="radio"
                                    checked={selectedSort === "allrec"}
                                    onChange={() => handleSortChange("allrec")}
                                  />
                                  <label for="allrec" className="mb-0">
                                    All
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="cat_type category-input filtersearchtype"
                                    data-label="For Sale"
                                    id="forsale"
                                    value="For Sale"
                                    name="pfor"
                                    type="radio"
                                    checked={selectedSort === "forsale"}
                                    onChange={() => handleSortChange("forsale")}
                                  />
                                  <label for="forsale" className="mb-0">
                                    For Sale
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    name="pfor"
                                    className="cat_type category-input filtersearchtype"
                                    id="forrent"
                                    value="For Rent"
                                    checked={selectedSort === "forrent"}
                                    onChange={() => handleSortChange("forrent")}
                                  />
                                  <label
                                    className="category-label"
                                    for="forrent"
                                  >
                                    For Rent
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div className="filtertogglefooter">
                              <button
                                type="button"
                                className="btn filterclosebutton done"
                                // onClick="return closetoggle();"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="btn-group pricetoggle">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn pricetogglebtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            fdprocessedid="tu974"
                          >
                            <span className="label-icon">Price</span>{" "}
                          </button>
                          <div
                            className="dropdown-menu homepricefilter "
                            onClick={handleDropdownClick}
                          >
                            <div className="p-2">
                              <h5 className="mb-3 mt-1">Price Range</h5>

                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control filterp"
                                  id="pricemin"
                                  name="pricemin"
                                  onChange={(e) =>
                                    setPrice({ ...price, min: e.target.value })
                                  }
                                  placeholder="Min"
                                  value={price.min}
                                />

                                <div className="input-group-prepend">
                                  <span className="input-group-text">to</span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control filterp"
                                  id="pricemax"
                                  name="pricemax"
                                  onChange={(e) =>
                                    setPrice({ ...price, max: e.target.value })
                                  }
                                  placeholder="Max"
                                  value={price.max}
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <ul
                                    id="min-options"
                                    className="filter-options pt-2 p-b2 filter"
                                  >
                                    {[
                                      "0",
                                      "100000",
                                      "200000",
                                      "300000",
                                      "400000",
                                      "500000",
                                      "600000",
                                      "700000",
                                      "800000",
                                      "900000",
                                    ].map((value) => (
                                      <li key={value} className="pt-1 pb-1">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handlePriceClick(value)
                                          }
                                        >
                                          ${Number(value).toLocaleString()}+
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul
                                    id="max-options"
                                    className="filter-options pt-2 p-b2 filter"
                                  >
                                    {[
                                      "900000",
                                      "1000000",
                                      "1250000",
                                      "1500000",
                                      "1750000",
                                      "2000000",
                                      "2250000",
                                      "2500000",
                                      "2750000",
                                      "3000000",
                                    ].map((value) => (
                                      <li key={value} className="pt-1 pb-1">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handlePriceClick(value)
                                          }
                                        >
                                          ${Number(value).toLocaleString()}+
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="filtertogglefooter">
                              <button
                                type="button"
                                className="btn filterclosebutton done"
                                // onClick="return closetoggle();"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="btn-group show">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            fdprocessedid="t3ola"
                          >
                            <span className="label-icon">Beds</span>{" "}
                          </button>
                          <div
                            className="dropdown-menu homebedfilter "
                            x-placement="bottom-start"
                            onClick={handleDropdownClick}
                          >
                            <div className="p-2">
                              <h5 className="mb-3 mt-1">Bedrooms</h5>

                              <div className="input-group ">
                                <div className="input-group-prepend">
                                  <button
                                    style={{ minWidth: "3.3em" }}
                                    onClick={() => updateBeds("sub")}
                                    className="btn btn-decrement btn-danger filterxp"
                                    type="button"
                                  >
                                    <i className="fa fa-minus-circle"></i>
                                  </button>
                                </div>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="number"
                                  className="form-control filterx"
                                  id="beds"
                                  name="beds"
                                  value={beds}
                                  step="1"
                                  min="0"
                                  max="10"
                                  onChange={handleBedInputChange}
                                />
                                <div className="input-group-append">
                                  <button
                                    style={{ minWidth: "3.3em" }}
                                    onClick={() => updateBeds("add")}
                                    className="btn btn-increment btn-primary filterxp"
                                    type="button"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </button>
                                </div>
                              </div>

                              <br />
                              <div
                                className="btn-group btn-group-toggle"
                                data-toggle="buttons"
                                id="bedsbtn"
                              >
                                {["Any", "1+", "2+", "3+", "4+", "5+"].map(
                                  (label, index) => (
                                    <label
                                      key={index}
                                      className="btn btn-light filter"
                                    >
                                      <input
                                        type="radio"
                                        className="bedsbtn"
                                        value={index === 0 ? "" : index}
                                        onChange={(e) =>
                                          setBeds(e.target.value)
                                        }
                                        checked={
                                          beds ===
                                          (index === 0 ? "" : index.toString())
                                        }
                                        autoComplete="off"
                                      />
                                      {label}
                                    </label>
                                  )
                                )}
                              </div>
                              <div className="custom-control custom-checkbox mr-sm-2 mt-2">
                                <input
                                  type="checkbox"
                                  className="custom-control-input checkbox filter"
                                  value="yes"
                                  name="bedexactmatch"
                                  id="bedexactmatch"
                                />
                                <label
                                  className="custom-control-label text-dark filter"
                                  for="bedexactmatch"
                                >
                                  Use Exact Match
                                </label>
                              </div>
                            </div>

                            <div className="filtertogglefooterbathbeds">
                              <button
                                type="button"
                                className="btn filterclosebutton done"
                                // onClick="return closetoggle();"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="btn-group show">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            fdprocessedid="i0yvs"
                          >
                            <span className="label-icon">Baths</span>{" "}
                          </button>
                          <div
                            className="dropdown-menu homebathfilter homebath_property"
                            x-placement="bottom-start"
                            onClick={handleDropdownClick}
                          >
                            <div className="p-2">
                              <h5 className="mb-3 mt-1">Bathrooms</h5>

                              <div className="input-group ">
                                <div className="input-group-prepend">
                                  <button
                                    style={{ minWidth: "3.3em" }}
                                    onClick={() => updateBaths("sub")}
                                    className="btn btn-decrement btn-danger  filterx"
                                    type="button"
                                  >
                                    <i className="fa fa-minus-circle"></i>
                                  </button>
                                </div>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="number"
                                  className="form-control filterxp"
                                  id="baths"
                                  name="baths"
                                  value={baths}
                                  step="1"
                                  min="0"
                                  max="10"
                                  onChange={handleBathInputChange}
                                />
                                <div className="input-group-append">
                                  <button
                                    style={{ minWidth: "3.3em" }}
                                    onClick={() => updateBaths("add")}
                                    className="btn btn-increment btn-primary filterx"
                                    type="button"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </button>
                                </div>
                              </div>
                              <br />
                              <div
                                className="btn-group btn-group-toggle"
                                data-toggle="buttons"
                                id="bathsbtn"
                              >
                                {["Any", "1+", "1.5+", "2+", "3+", "4+"].map(
                                  (label, index) => (
                                    <label
                                      key={index}
                                      className="btn btn-light filter"
                                    >
                                      <input
                                        type="radio"
                                        className="bedsbtn"
                                        value={index === 0 ? "" : index}
                                        onChange={(e) =>
                                          setBaths(e.target.value)
                                        }
                                        checked={
                                          beds ===
                                          (index === 0 ? "" : index.toString())
                                        }
                                        autoComplete="off"
                                      />
                                      {label}
                                    </label>
                                  )
                                )}
                              </div>
                              <div className="custom-control custom-checkbox mr-sm-2 mt-2">
                                {" "}
                                <input
                                  type="checkbox"
                                  className="custom-control-input checkbox filter"
                                  value="yes"
                                  name="bathexactmatch"
                                  id="bathexactmatch"
                                />{" "}
                                <label
                                  className="custom-control-label text-dark filter"
                                  for="bathexactmatch"
                                >
                                  Use Exact Match
                                </label>
                              </div>
                            </div>

                            <div className="filtertogglefooterbathbeds">
                              <button
                                type="button"
                                className="btn filterclosebuttonn done"
                                // onClick="return closetoggle();"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="btn-group show">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-split searchtogbtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            fdprocessedid="hl4rz"
                          >
                            <span className="label-icon">More</span>
                          </button>
                          <div
                            className="dropdown-menu homemorefilter "
                            x-placement="bottom-start"
                            onClick={handleDropdownClick}
                          >
                            <div className="p-2">
                              <div className="row hidesfield">
                                <div className="col-md-5 lheight35">
                                  <label for="hoa">Max hoa </label>
                                </div>
                                <div className="col-md-7">
                                  <div className="fields twocol-column_right">
                                    <select
                                      id="hoa"
                                      name="hoa"
                                      aria-labelledby="hoa"
                                      className="form-control filter"
                                      onChange={handleHoaChange}
                                      value={hoaFee}
                                    >
                                      <option value="0">Any</option>
                                      <option value="0">No HOA Fee</option>
                                      <option value="50">$50/month</option>
                                      <option value="100">$100/month</option>
                                      <option value="200">$200/month</option>
                                      <option value="300">$300/month</option>
                                      <option value="400">$400/month</option>
                                      <option value="500">$500/month</option>
                                      <option value="600">$600/month</option>
                                      <option value="700">$700/month</option>
                                      <option value="800">$800/month</option>
                                      <option value="900">$900/month</option>
                                      <option value="1000">$1000/month</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row ">
                                <div className="col-md-12 ">
                                  <label>Tours </label>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-6 hidesfield ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="openhouse"
                                      id="openhouse"
                                      value="yes"
                                      checked={openHouseChecked}
                                      onChange={handleMoreChange}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="openhouse"
                                    >
                                      Must have open house
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="3dhouse"
                                      name="3dhouse"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={threeDChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="3dhouse"
                                    >
                                      Must have 3D Home
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2 hidesfield">
                                <div className="col-md-5 lheight35">
                                  <label for="parkingspots">
                                    Parking spots{" "}
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <div className="fields twocol-column_right">
                                    <select
                                      id="parkingspots"
                                      name="parkingspots"
                                      className="form-control filter"
                                      onChange={handleParkingSpotsChange}
                                      value={selectedParkingSpots}
                                    >
                                      <option value="0">Any</option>
                                      <option value="1">1+</option>
                                      <option value="2">2+</option>
                                      <option value="3">3+</option>
                                      <option value="4">4+</option>
                                    </select>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="hasgarage"
                                      name="hasgarage"
                                      value="yes"
                                      checked={hasGarageChecked}
                                      onChange={handleMoreChange}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="hasgarage"
                                    >
                                      Must have garage
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-md-5 lheight35">
                                  <label for="sqftmin">Square Feet</label>
                                </div>
                                <div className="col-md-7 ">
                                  <div className="input-group">
                                    <select
                                      id="sqftmin"
                                      name="sqftmin"
                                      className="form-control filter"
                                      onChange={handleMinSquareChange}
                                      value={minSquare}
                                    >
                                      <option>Any</option>
                                      <option value="500">500</option>
                                      <option value="750">750</option>
                                      <option value="1000">1,000</option>
                                      <option value="1250">1,250</option>
                                      <option value="1500">1,500</option>
                                      <option value="1750">1,750</option>
                                      <option value="2000">2,000</option>
                                      <option value="2250">2,250</option>
                                      <option value="2500">2,500</option>
                                      <option value="2750">2,750</option>
                                      <option value="3000">3,000</option>
                                      <option value="3500">3,500</option>
                                      <option value="4000">4,000</option>
                                      <option value="5000">5,000</option>
                                      <option value="7500">7,500</option>
                                    </select>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        -
                                      </span>
                                    </div>
                                    <select
                                      id="sqftmax"
                                      name="sqftmax"
                                      className="form-control filter"
                                      onChange={handleMaxSquareChange}
                                      value={maxSquare}
                                    >
                                      <option value="500">500</option>
                                      <option value="750">750</option>
                                      <option value="1000">1,000</option>
                                      <option value="1250">1,250</option>
                                      <option value="1500">1,500</option>
                                      <option value="1750">1,750</option>
                                      <option value="2000">2,000</option>
                                      <option value="2250">2,250</option>
                                      <option value="2500">2,500</option>
                                      <option value="2750">2,750</option>
                                      <option value="3000">3,000</option>
                                      <option value="3500">3,500</option>
                                      <option value="4000">4,000</option>
                                      <option value="5000">5,000</option>
                                      <option value="7500">7,500</option>
                                      <option>Any</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-5 lheight35">
                                  <label for="lotsizemin">Lot Size</label>
                                </div>
                                <div className="col-md-7 ">
                                  <div className="input-group">
                                    <select
                                      id="lotsizemin"
                                      name="lotsizemin"
                                      className="form-control filter"
                                      value={minLotSize}
                                      onChange={handleMinLotSizeChange}
                                    >
                                      <option>Any</option>
                                      <option value="1000">1,000 sqft</option>
                                      <option value="2000">2,000 sqft</option>
                                      <option value="3000">3,000 sqft</option>
                                      <option value="4000">4,000 sqft</option>
                                      <option value="5000">5,000 sqft</option>
                                      <option value="7500">7,500 sqft</option>
                                      <option value="10890">
                                        1/4 acre/10,890 sqft
                                      </option>
                                      <option value="21780">1/2 acre</option>
                                      <option value="43560">1 acre</option>
                                      <option value="87120">2 acres</option>
                                      <option value="217800">5 acres</option>
                                      <option value="435600">10 acres</option>
                                      <option value="871200">20 acres</option>
                                      <option value="2178000">50 acres</option>
                                      <option value="4356000">100 acres</option>
                                    </select>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        -
                                      </span>
                                    </div>
                                    <select
                                      id="lotsizemax"
                                      name="lotsizemax"
                                      className="form-control filter"
                                      value={maxLotSize}
                                      onChange={handleMaxLotSizeChange}
                                    >
                                      <option value="1000">1,000 sqft</option>
                                      <option value="2000">2,000 sqft</option>
                                      <option value="3000">3,000 sqft</option>
                                      <option value="4000">4,000 sqft</option>
                                      <option value="5000">5,000 sqft</option>
                                      <option value="7500">7,500 sqft</option>
                                      <option value="10890">
                                        1/4 acre/10,890 sqft
                                      </option>
                                      <option value="21780">1/2 acre</option>
                                      <option value="43560">1 acre</option>
                                      <option value="87120">2 acres</option>
                                      <option value="217800">5 acres</option>
                                      <option value="435600">10 acres</option>
                                      <option value="871200">20 acres</option>
                                      <option value="2178000">50 acres</option>
                                      <option value="4356000">100 acres</option>
                                      <option>Any</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-5 lheight35">
                                  <label for="yearbuildmin">Year Built</label>
                                </div>
                                <div className="col-md-7 ">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control border filter"
                                      placeholder="Min"
                                      id="yearbuildmin"
                                      name="yearbuildmin"
                                      value={minYear}
                                      onChange={handleMinYear}
                                    />
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        -
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control border filter"
                                      placeholder="Max"
                                      id="yearbuildmax"
                                      name="yearbuildmax"
                                      value={maxYear}
                                      onChange={handleMaxYear}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-5 ">
                                  <label for="basement">Basement</label>
                                </div>
                                <div className="col-md-7 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="basement"
                                      name="basement"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={basement}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="basement"
                                    >
                                      Has Basement
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-5 ">
                                  <label for="noofstories">
                                    Number Of Stories
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="noofstories"
                                      name="noofstories"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={story}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="noofstories"
                                    >
                                      Single-story only
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row rentfieldshow">
                                <div className="col-md-12 ">
                                  <label>Pets </label>
                                </div>
                              </div>
                              <div className="row mb-3 rentfieldshow">
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="petsldogs"
                                      id="petsldogs"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={largeDogsChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="petsldogs"
                                    >
                                      Allows large dogs
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="petscats"
                                      name="petscats"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={catsChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="petscats"
                                    >
                                      Allows cats
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="petssdos"
                                      name="petssdos"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={smallDogsChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="petssdos"
                                    >
                                      Allows small dogs
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 lheight35">
                                  <label>Other Amenities </label>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="ameac"
                                      id="ameac"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={acChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="ameac"
                                    >
                                      Must have A/C
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6  rentfieldshow">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="ameinunit"
                                      id="ameinunit"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={laundryChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="ameinunit"
                                    >
                                      In-unit Laundry
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="amepool"
                                      name="amepool"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={poolChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="amepool"
                                    >
                                      Must have pool
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6  rentfieldshow">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="ameinmhcapp"
                                      id="ameinmhcapp"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={mhcAppChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="ameinmhcapp"
                                    >
                                      Accepts MHC Applications
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="amewaterfront"
                                      name="amewaterfront"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={waterfrontChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="amewaterfront"
                                    >
                                      Waterfront
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6  rentfieldshow">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="ameinincomeres"
                                      id="ameinincomeres"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={incomeRestrictedChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="ameinincomeres"
                                    >
                                      Income restricted
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6  rentfieldshow">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="ameinonsitep"
                                      id="ameinonsitep"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={onsiteParkingChecked}
                                    />

                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="ameinonsitep"
                                    >
                                      On-site Parking
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row ">
                                <div className="col-md-12 ">
                                  <label>View </label>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="viewcity"
                                      id="viewcity"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={viewCityChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="viewcity"
                                    >
                                      City
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="viewpark"
                                      name="viewpark"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={viewParkChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="viewpark"
                                    >
                                      Park
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="viewmountain"
                                      name="viewmountain"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={viewMountainChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="viewmountain"
                                    >
                                      Mountain
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      id="viewwater"
                                      name="viewwater"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={viewWaterChecked}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="viewwater"
                                    >
                                      Water
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2 hidesfield">
                                <div className="col-md-4 ">
                                  <label for="mhcowned">MHC Owned </label>
                                </div>
                                <div className="col-md-8 ">
                                  <div className="custom-control custom-checkbox mr-sm-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input filter"
                                      name="mhcowned"
                                      id="mhcowned"
                                      value="yes"
                                      onChange={handleMoreChange}
                                      checked={mhcOwned}
                                    />
                                    <label
                                      className="custom-control-label text-dark filter"
                                      for="mhcowned"
                                    >
                                      Move-in ready homes, evaluated and
                                      repaired by MHC
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-4 lheight35">
                                  <label for="daysonmhc">Days on MHC</label>
                                </div>
                                <div className="col-md-8 lheight35">
                                  <select
                                    id="daysonmhc"
                                    name="daysonmhc"
                                    className="form-control filter"
                                    onChange={handleMhcDaysChange}
                                    value={mhcDays}
                                  >
                                    <option>Any</option>
                                    <option value="1">1 day</option>
                                    <option value="7">7 days</option>
                                    <option value="14">14 days</option>
                                    <option value="30">30 days</option>
                                    <option value="90">90 days</option>
                                    <option value="6m">6 months</option>
                                    <option value="12m">12 months</option>
                                    <option value="24m">24 months</option>
                                    <option value="36m">36 months</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-md-4 lheight35">
                                  <label for="skeywords">Keywords</label>
                                </div>
                                <div className="col-md-8 ">
                                  <input
                                    type="text"
                                    id="skeywords"
                                    name="skeywords"
                                    className="form-control border filter"
                                    placeholder="Yard, fireplace, horses, etc."
                                    value={keywords}
                                    onChange={handleKeywords}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="filtertogglefooter">
                              <button
                                type="button"
                                className="btn filterclosebutton done"
                                // onClick="return closetoggle();"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>

                        <button
                          id="searchBtn"
                          type="button"
                          className="btn btn-secondary btn-search searchbtn_property"
                        >
                          {" "}
                          <span className="label-icon">SAVE SEARCH</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </figure>
              </figure>
            </article>
          </section>
        </header>

        <article id="result-section">
          <section className="container-fluid">
            <figure className="row">
              <figure className="row mb-4">
                <div className="col-md-12" id="searchfilterarea">
                  {selectedSort && (
                    <div className="" id="searchfilterarea">
                      <div className="float-left m-1">
                        <div className="tagtextarea">
                          Sort By:
                          {selectedSort === "allrec"
                            ? " All"
                            : selectedSort === "forsale"
                            ? " For Sale"
                            : " For Rent"}
                        </div>
                        <button
                          className="tagbtnarea"
                          onClick={clearSortFilter}
                          fdprocessedid="fa7dve"
                        >
                          x
                        </button>
                      </div>
                    </div>
                  )}
                  {showFilter && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Search: {search}</div>
                      <button
                        className="tagbtnarea"
                        onClick={clearFilter}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {price.min && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Min Price: ${price.min}</div>
                      <button
                        className="tagbtnarea"
                        onClick={() => removePrice("min")}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {price.max && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Max Price: ${price.max}</div>
                      <button
                        className="tagbtnarea"
                        onClick={() => removePrice("max")}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {beds && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Beds: {beds}+ </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearBed}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {baths && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Baths: {baths}+ </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearBath}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {hoaFee && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Hoa: ${hoaFee}/month </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearHoaFilter}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {openHouseChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Must Have: Open House </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearOpenHouse}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {threeDChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Must Have: 3D Home </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearThreed}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {hasGarageChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Must Have: Garage </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearHaveGarage}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {basement && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Has Basement </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearBasement}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {story && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        {" "}
                        Number Of Stories: Single-story only{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearStories}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {largeDogsChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea"> Pets: Large Dogs </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearLargeDogs}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {smallDogsChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Pets: Small Dogs </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearSmalleDogs}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {catsChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Pets: Cats </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearCats}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {acChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Must Have: Ac </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearAc}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {laundryChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">In-unit laundary </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearLaundary}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {mhcAppChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Accepts MHC Applications{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMhcApplication}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {poolChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Must Have: Pool </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearPool}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {waterfrontChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Amenities: Waterfront </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearWaterfront}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {incomeRestrictedChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Amenities: Income Restricted{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearIncomeRestricted}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {onsiteParkingChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Amenities: On-site Parking{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearOnSitePArking}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {viewCityChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">View: City </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearCity}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {viewParkChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">View: Park </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearPark}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {viewMountainChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">View: Mountain </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMountain}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {viewWaterChecked && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">View: Water </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearWater}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {mhcOwned && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">MHC Owned </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMhcOwned}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {minSquare && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Min Square: {minSquare}{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMinSquare}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {maxSquare && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Max Square: {maxSquare}{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMaxSquare}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {minLotSize && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Min Lot Size: {minLotSize}{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMinLotSize}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {maxLotSize && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        Max Lot Size: {maxLotSize}{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMaxLotSize}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {minYear && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Min Year: {minYear} </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMinYear}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {maxYear && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Max Year: {maxYear} </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearMaxYear}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {mhcDays && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Mhc Days: {mhcDays} </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearDaysOnMhc}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {keywords && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">Keywords: {keywords} </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearKeywords}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {selectedParkingSpots && (
                    <div className="float-left m-1">
                      <div className="tagtextarea">
                        {" "}
                        Parking Spots: {selectedParkingSpots}+{" "}
                      </div>
                      <button
                        className="tagbtnarea"
                        onClick={clearParkingSpot}
                        fdprocessedid="fa7dve"
                      >
                        x
                      </button>
                    </div>
                  )}
                  {selected.length !== card.length && (
                    <div className="" id="searchfilterarea">
                      {selected.map((property) => (
                        <div className="float-left m-1">
                          <div className="tagtextarea">Type: {property}</div>
                          <button
                            className="tagbtnarea"
                            onClick={() => removeTag(property)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </figure>
              <article className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-left sort-bar">
                <h1>
                  {propertyCount} {propertyCount <= 1 ? "Listing" : "Listings"}{" "}
                  <span>{headerText}</span>
                </h1>
                <h3>Updated 10 minutes ago</h3>
              </article>
              <article className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right">
                <a href="/">
                  <img
                    alt=""
                    className="search-img"
                    src="images/result-img.png"
                  />
                </a>
              </article>
            </figure>

            <figure className="row search-section">
              <PropertyCard
                setProgress={setProgress}
                search={search}
                selected={selected}
                minPrice={price.min}
                maxPrice={price.max}
                beds={beds}
                baths={baths}
                hoaFee={hoaFee}
                openHouse={openHouseChecked}
                threeD={threeDChecked}
                largeDogs={largeDogsChecked}
                cats={catsChecked}
                smallDogs={smallDogsChecked}
                ac={acChecked}
                laundry={laundryChecked}
                pool={poolChecked}
                acceptsMhc={mhcAppChecked}
                waterFront={waterfrontChecked}
                onsiteParking={onsiteParkingChecked}
                cityv={viewCityChecked}
                mountain={viewMountainChecked}
                park={viewParkChecked}
                water={viewWaterChecked}
                parkingSpots={selectedParkingSpots}
                haveGarage={hasGarageChecked}
                basement={basement}
                stories={story}
                mhcOwned={mhcOwned}
                mhcDays={mhcDays}
                income={incomeRestrictedChecked}
                maxSquare={maxSquare}
                minSquare={minSquare}
                minLotSize={minLotSize}
                maxLotSize={maxLotSize}
                onFilteredCountUpdate={setPropertyCount}
                minYear={minYear}
                maxYear={maxYear}
                keywords={keywords}
                selectedSort={selectedSort}
                visibleCount={visibleCount} // Pass visibleCount to AgentCard
                setHasMore={setHasMore}
              />
            </figure>

            {hasMore && (
              <div ref={loader} className="result-btn text-center">
                <a className="read-more" href="/">
                  Please Wait...{" "}
                </a>
              </div>
            )}
          </section>
        </article>
      </article>
      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/terms">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/policy">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/terms">Business Directory</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/terms">About Us</Link>
                </li>
                <li>
                  <Link to="/terms">Careers</Link>
                </li>
                <li>
                  {/* <a href="#contact-popup" className="open-popup-link"> */}
                  <ContactModal />
                  {/* </a> */}
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info" alt="">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default SearchResult;
