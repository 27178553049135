import React from "react";
import { Link } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
import AuthModal from "../modals/AuthModal";
function Policy() {
  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12">
                  <nav className="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES{" "}
                        <span className="badge topbadge themebg">4</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES{" "}
                        <span className="badge topbadge themebg">0</span>
                      </a>
                    </li>
                    <li>
                      <AuthModal />
                    </li>
                  </nav>

                  <nav className="main-menu term-head text-left">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </nav>
                </figure>
              </figure>
              <figure className="row">
                <div className="bs-example" style={{paddingLeft:0,paddingRight:0}}>
                  <figure className="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    className="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="fa fa-bars text-white"></span>
                    </button>
                  </nav>

                  <div
                    className="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Va Loan</Link>
                    </li>
                    <li>
                      <Link to="/">Relocation</Link>
                    </li>
                    <li>
                      <Link to="/">Resources</Link>
                    </li>
                    <li>
                      <Link to="/">Advertise</Link>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </div>
                </div>
                <figure className="col-md-3  logoback">
                  <Link to="/">
                    <img className="logo" src="images/logo5.png" alt="" />
                  </Link>
                </figure>
                <figure className="offset-md-2 col-md-7 searching">
                  <div className="row justify-content-right">
                    <div className=" col-md-12 text-right input-group md-form form-sm form-2 pl-0">
                      <input
                        className="form-control my-0 py-1 red-border"
                        type="text"
                        placeholder="Search Properties by Military Installation, Address, City, County, Zip Code or MLS #"
                        aria-label="Search"
                        style={{ fontSize: "17px" }}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text red lighten-3"
                          id="basic-text1"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </figure>
              </figure>
            </article>
          </section>
        </header>

        <article id="term-section">
          <section className="container-fluid">
            <figure className="row">
              <figure className="col-md-12">
                <h1>
                  Privacy Of <span>Our Users</span>
                </h1>
                <p>
                  Military housing center respects the privacy of our users and
                  has developed this Privacy Policy to demonstrate its
                  commitment to protecting your privacy. This Privacy Policy is
                  intended to describe for you, as an individual who is a user
                  of Military Housing Center (and all websites and URL’s
                  controlled or operated by Military Housing Center which link
                  to this policy, unless otherwise specified) or our services,
                  or otherwise provide us with information through various means
                  the information we collect, how that information may be used,
                  with whom it may be shared, and your choices about such uses
                  and disclosures.
                </p>

                <p>
                  We encourage you to read this Privacy Policy carefully when
                  using our website or services or transacting business with us.
                  By using our website, you are accepting the practices
                  described in this Privacy Policy.
                </p>

                <p>
                  If you have any questions about our privacy practices, please
                  refer to the end of this Privacy Policy for information on how
                  to contact us.
                </p>

                <h1>
                  Information we <span>collect about you</span>
                </h1>
                <h2>
                  In <span>General.</span>
                </h2>
                <p>
                  We may collect personal information that can identify you such
                  as your name and email address and other information that does
                  not identify you. When you provide personal information
                  through our website, the information may be sent to servers
                  located in the United States and other countries around the
                  world.
                </p>

                <h2>
                  Information <span>you provide.</span>
                </h2>
                <p>
                  We may collect and store any personal information you enter on
                  our website or provide to us in some other manner. This
                  includes identifying information, such as your name, address,
                  e-mail address, and telephone number, and in addition, for
                  Professionals, your credit card number and other personally
                  identifiable information. We also may request information
                  about your interests and activities, your gender and age, and
                  other demographic information.
                </p>

                <h2>
                  Information <span>from other sources.</span>
                </h2>
                <p>
                  We may also periodically obtain both personal and non-personal
                  information about you from other business partners,
                  contractors and other third parties. Examples of information
                  that we may receive include (but are not limited to): updated
                  delivery and address information, purchase history, and
                  additional demographic information.
                </p>

                <h2>
                  Information <span>about others.</span>
                </h2>
                <p>
                  We may also collect and store personal information about other
                  people that you provide to us. If you use our website to send
                  others (friends, relatives, colleagues, etc.) information that
                  may interest them through our system, we may store your
                  personal information, and the personal information of each
                  such recipient.
                </p>

                <p>
                  Use of cookies and other technologies to collect information.
                  We use various technologies to collect information from your
                  computer and about your activities on our site.
                </p>

                <h2>
                  Information <span>collected automatically. </span>
                </h2>
                <p>
                  We automatically collect information from your browser when
                  you visit our website. This information includes your IP
                  address, your browser type and language, access times, the
                  content of any undeleted cookies that your browser previously
                  accepted from us (see “Cookies” below), and the referring
                  website address.
                </p>

                <h2>Cookies.</h2>
                <p>
                  When you visit our website, we may assign your computer one or
                  more cookies to facilitate access to our site and to
                  personalize your online experience. Through the use of a
                  cookie, we also may automatically collect information about
                  your online activity on our site, such as the web pages you
                  visit, the links you click, and the searches you conduct on
                  our site. Most browsers automatically accept cookies, but you
                  can usually modify your browser setting to decline cookies. If
                  you choose to decline cookies, please note that you may not be
                  able to sign in or use some of the interactive features
                  offered on our website.
                </p>

                <h2>
                  Other <span>Technologies. </span>
                </h2>
                <p>
                  We may use standard Internet technology, such as web beacons
                  and other similar technologies, to track your use of our site.
                  We also may include web beacons in promotional [or other]
                  e-mail messages or newsletters to determine whether messages
                  have been opened and acted upon. The information we obtain in
                  this manner enables us to customize the services we offer our
                  website visitors to deliver targeted advertisements and to
                  measure the overall effectiveness of our online advertising,
                  content, programming or other activities.
                </p>

                <h2>
                  Information collected <span>by third-parties. </span>
                </h2>
                <p>
                  We may allow third-parties, including (but not limited to) our
                  authorized service providers, advertising companies, and ad
                  networks, to display advertisements on our site. These
                  companies may use tracking technologies, such as cookies, to
                  collect information about users who view or interact with
                  their advertisements. Our website does not provide any
                  personal information to these third parties. This information
                  allows them to deliver targeted advertisements and gauge their
                  effectiveness. Some of these third-party advertising companies
                  may be advertising networks that are members of the Network
                  Advertising Initiative, which offers a single location to opt
                  out of ad targeting from member companies
                  (www.networkadvertising.org).
                </p>
              </figure>
            </figure>
          </section>
        </article>
      </article>

      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/policy">Business Directory</Link>
                </li>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/policy">About Us</Link>
                </li>
                <li>
                  <Link to="/policy">Careers</Link>
                </li>
                <li>
                  <ContactModal />
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default Policy;
