import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { ModalContext } from "./ModalContext";

const AuthModal = () => {
  const [formType, setFormType] = useState("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const { isAuthModalOpen, openAuthModal, closeAuthModal } =
    useContext(ModalContext);
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLogin = () => {
    axios
      .post("https://backend.tanzee.pk/api/userlogin", {
        email: email,
        password: password,
      })
      .then((result) => {
        console.log(result);
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userName", result.data.name);
        localStorage.setItem("uid", result.data.uid);
        closeAuthModal();
      
      })
      .catch((error) => {
        console.error(
          "Login Error:",
          error.response ? error.response.data : error.message
        );
        alert(
          "Issue during login: " +
            (error.response ? error.response.data.message : error.message)
        );
      });
  };
  const handleSignup = () => {
    axios
      .post("https://backend.tanzee.pk/api/userregister", {
        name: name,
        email: email,
        password: password,
      })
      .then((result) => {
        alert("Signup Successful, please login.");
        console.log(result);
        setFormType("signin");
      })
      .catch((error) => {
        console.error(
          "Signup Error:",
          error.response ? error.response.data : error.message
        );
        alert(
          "Issue during signup: " +
            (error.response ? error.response.data.message : error.message)
        );
      });
  };

  const renderForm = () => {
    switch (formType) {
      case "signin":
        return (
          <>
            <section className="container-fluid p-0">
              <figure className="row">
                <img
                  src="../images/x-icon.png"
                  alt=""
                  onClick={closeAuthModal}
                  style={{
                    cursor: "pointer",
                    width: "52px",
                    height: "30px",
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    marginTop: "20px",
                  }}
                />
                <figure className="col-md-6 member-left">
                  <h1>
                    WELCOME TO
                    <br />
                    <span>MILITARY HOUSING CENTER</span>
                  </h1>

                  <div className="login-left text-center">
                    <img
                      alt=""
                      className="text-center"
                      src="images/login-left-img.png"
                    />
                  </div>
                  <h4>
                    <a
                      href="#become-member-popup"
                      className="open-popup-link"
                      onClick={() => setFormType("signup")}
                    >
                      Not a member? Sign up
                    </a>
                  </h4>
                </figure>
                <figure className="col-md-6 member-right p-5">
                  <h1>
                    MEMBER <span>LOGIN</span>
                  </h1>

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/mail-icon.jpg" />
                      </span>
                    </div>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmail}
                    />
                  </div>
                  <br />

                  <div className="input-group" style={{ position: "relative" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/key-icon.jpg" />
                      </span>
                    </div>
                    <input
                      id="password-field"
                      type="password"
                      name=""
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={handlePassword}
                    />
                    <span
                      toggle="#password-field"
                      className="fa fa-fw fa-eye field-icon toggle-password"
                      style={{ position: "absolute", right: 0 }}
                    ></span>
                  </div>
                  <br />

                  <div className="checkbox">
                    <p style={{ float: "right", pt: "4px" }}>
                      <a
                        href="#forget-popup"
                        className="open-popup-link"
                        onClick={() => setFormType("forgotpassword")}
                      >
                        Forget Password?
                      </a>
                    </p>
                    <label>
                      <input type="checkbox" value="" />
                       keep me Login
                    </label>
                  </div>
                  <br />
                  <button
                    type="submit"
                    className="read-more"
                    onClick={handleLogin}
                  >
                    <span className="glyphicon glyphicon-off"></span>
                    <img alt="" src="images/lock-icon.png" />
                    Login{" "}
                  </button>

                  <p className="text-center">
                    <span>Or Connect With</span>{" "}
                    <img alt="" src="images/mem-icon-1.png" />
                    <img alt="" src="images/mem-icon-2.png" />
                    <img alt="" src="images/mem-icon-3.png" />
                    <img alt="" src="images/mem-icon-4.png" />
                    <img alt="" src="images/mem-icon-5.png" />
                  </p>
                </figure>
              </figure>
            </section>
          </>
        );
      case "signup":
        return (
          <>
            <section className="container-fluid p-0">
              <figure className="row">
                <img
                  src="../images/x-icon.png"
                  alt=""
                  onClick={closeAuthModal}
                  style={{
                    cursor: "pointer",
                    width: "52px",
                    height: "30px",
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    marginTop: "20px",
                  }}
                />
                <figure className="col-md-6 member-left">
                  <h1>
                    WELCOME TO <br />
                    <span>MILITARY HOUSING CENTER</span>
                  </h1>
                  <h3>Exclusive Membership Benefits</h3>
                  <nav>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      100% Free Membership, Always will be
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      Free Relocation Assistance
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      Free Property Postings
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      Save and Track Searches
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      Ability to Save Favorites
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      Post Ratings and Reviews
                    </li>
                    <li>
                      <img alt="" src="images/mem-icon.png" />
                      And more...{" "}
                    </li>

                    <h4>
                      <a
                        href="#member-popup"
                        className="open-popup-link"
                        onClick={() => setFormType("signin")}
                      >
                        Already a member? Sign in
                      </a>
                    </h4>
                    <p>
                      By registering, I accept the MilitaryHousingCenter.com
                      Terms of Use.
                    </p>
                  </nav>
                </figure>
                <figure className="col-md-6 member-right p-5">
                  <h1>
                    BECOME A <span>MEMBER</span>
                  </h1>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/army-icon.jpg" />
                      </span>
                    </div>

                    <input
                      id="name-field"
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={handleName}
                    />
                    <span
                      toggle="#name-field"
                      // className="fa fa-fw fa-eye field-icon toggle-password"
                      style={{ position: "absolute", right: 0 }}
                    ></span>
                  </div>

                  <br />
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/mail-icon.jpg" />
                      </span>
                    </div>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmail}
                    />
                  </div>
                  <br />

                  <div className="input-group" style={{ position: "relative" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/key-icon.jpg" />
                      </span>
                    </div>
                    <input
                      id="password-field"
                      type="Password"
                      name=""
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={handlePassword}
                    />
                    <span
                      toggle="#password-field"
                      className="fa fa-fw fa-eye field-icon toggle-password"
                      style={{ position: "absolute", right: 0 }}
                    ></span>
                  </div>
                  <br />

                  {/* <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/army-icon.jpg" />
                      </span>
                    </div>

                    <input
                      id="name-field"
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={handleName}
                    />
                    <span
                      toggle="#name-field"
                      // className="fa fa-fw fa-eye field-icon toggle-password"
                      style={{ position: "absolute", right: 0 }}
                    ></span>
                  </div>

                  
                  <br /> */}
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" value="" />
                       I am a landlord or industry professional.
                    </label>
                  </div>
                  <br />
                  <button
                    type="submit"
                    className="read-more"
                    onClick={handleSignup}
                  >
                    <span className="glyphicon glyphicon-off"></span>
                    <img alt="" src="images/submit-icon.png" /> Become a Member{" "}
                  </button>

                  <p className="text-center">
                    <span>Or Connect With</span>{" "}
                    <img alt="" src="images/mem-icon-1.png" />
                    <img alt="" src="images/mem-icon-2.png" />
                    <img alt="" src="images/mem-icon-3.png" />
                    <img alt="" src="images/mem-icon-4.png" />
                    <img alt="" src="images/mem-icon-5.png" />
                  </p>
                </figure>
              </figure>
            </section>
          </>
        );
      case "forgotpassword":
        return (
          <>
            <section className="container-fluid p-0">
              <figure className="row">
                <img
                  src="../images/x-icon.png"
                  alt=""
                  onClick={closeAuthModal}
                  style={{
                    cursor: "pointer",
                    width: "52px",
                    height: "30px",
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    marginTop: "20px",
                  }}
                />
                <figure className="col-md-6 member-left">
                  <h1>
                    WELCOME TO <br />
                    <span>MILITARY HOUSING CENTER</span>
                  </h1>

                  <div className="login-left text-center">
                    <img
                      alt=""
                      className="text-center"
                      src="images/forget-img.png"
                    />
                  </div>
                </figure>
                <figure className="col-md-6 member-right p-5">
                  <h1>
                    FORGOT <span>PASSWORD </span>
                  </h1>
                  <div className="forget-text">
                    <p>
                      Please enter your email, and we'll send you a link to
                      change your password.
                    </p>
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img alt="" src="images/mail-icon.jpg" />
                      </span>
                    </div>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <br />

                  <button type="submit" className="read-more">
                    <span className="glyphicon glyphicon-off"></span>
                    <img alt="" src="images/forget-key-icon.png" /> RESET
                    PASSWORD{" "}
                  </button>
                  <div className="forget-text">
                    <p className="text-center forget-btm">
                      Already have your password?{" "}
                      <a
                        href="#member-popup"
                        className="open-popup-link"
                        onClick={() => setFormType("signin")}
                      >
                        click here to Login
                      </a>
                    </p>
                  </div>
                </figure>
              </figure>
            </section>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="dropdown" style={{ width: "auto" }}>
          <button
            type="button"
            className="hi-drop dropdown-toggle"
            data-toggle="dropdown"
          >
            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
            Hi {localStorage.getItem("userName")}
          </button>
          <div className="hi-drop dropdown-menu">
            <Link className="hi-drop dropdown-item" to="/dashboard">
              Dashboard
            </Link>
            <a className="hi-drop dropdown-item" href="/">
              Edit Account
            </a>
            <Link
              className="hi-drop dropdown-item"
              to="/"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userName");
                localStorage.removeItem("uid");
              }}
            >
              Logout
            </Link>
          </div>
        </div>
      ) : (
        <a
          style={{ color: "white", cursor: "pointer" }}
          href="#"
          onClick={openAuthModal}
        >
          <i className="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;&nbsp;
          Login
        </a>
      )}

      <Modal
        show={isAuthModalOpen}
        closebutton="true"
        onHide={closeAuthModal}
        size="xl"
      >
        {renderForm()}
      </Modal>
    </>
  );
};
export default AuthModal;
