import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactModal from "../modals/ContactModal";
import axios from "axios";
function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [favourites, setFavourites] = useState([]);
  const uid = localStorage.getItem("uid");

  useEffect(() => {
    if (uid) {
      fetchFavourites(uid);
    }
  }, [uid]);

  const fetchFavourites = (uid) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`https://backend.tanzee.pk/api/favourites/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setFavourites(response.data.favourites);
        })
        .catch((error) => {
          console.error("Error fetching favourites:", error);
        });
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return (
          <div>
            <h1>Dashboard Content</h1>
            <p>Yeh Dashboard ka content hai.</p>
          </div>
        );
      case "notifications":
        return (
          <div>
            <h1>Notifications Content</h1>
            <p>Yeh Notifications ka content hai.</p>
          </div>
        );
      case "listings":
        return (
          <div>
            <h1>My Listings Content</h1>
            <p>Yeh My Listings ka content hai.</p>
          </div>
        );
      case "favoriteProperties":
        return (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fa fa-heart-o " style={{ color: "#c70000" }}></i>{" "}
                Favorite Properties
              </h3>
            </div>
            <div className="card-body">
              <div
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="example_length">
                      <label>
                        Show{" "}
                        <select
                          name="example_length"
                          aria-controls="example"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          fdprocessedid="v3qv5e"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div id="example_filter" className="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="example"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      id="example"
                      className="table table-striped table-bordered dataTable no-footer"
                      style={{ width: "100%" }}
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Thumbnail: activate to sort column ascending"
                            style={{ width: "83px" }}
                          >
                            Thumbnail
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Title: activate to sort column ascending"
                            style={{ width: "44px" }}
                          >
                            Title
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Address: activate to sort column ascending"
                            style={{ width: "141px" }}
                          >
                            Address
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Beds/Baths: activate to sort column ascending"
                            style={{ width: "86px" }}
                          >
                            Beds/Baths
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Sqft: activate to sort column ascending"
                            style={{ width: "32px" }}
                          >
                            Sqft
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Price: activate to sort column ascending"
                            style={{ width: "38px" }}
                          >
                            Price
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Type: activate to sort column ascending"
                            style={{ width: "42px" }}
                          >
                            Type
                          </th>
                          <th
                            className="sorting_desc"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-sort="descending"
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "65px" }}
                          >
                            Date
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Status: activate to sort column ascending"
                            style={{ width: "47px" }}
                          >
                            Status
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Action: activate to sort column ascending"
                            style={{ width: "270px" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(favourites) && favourites.length > 0 ? (
                          favourites
                            .filter((fav) => fav.property) // Filter to include only items with property data
                            .map((fav, index) => (
                              <tr key={index} className="odd">
                                <td className="text-center">
                                  <img
                                    src={
                                      fav.property.propphoto ||
                                      "https://via.placeholder.com/100"
                                    }
                                    alt="Property Thumbnail"
                                    width="100px"
                                  />
                                  <br />
                                  {fav.property.propertytype || "Property"}
                                </td>
                                <td>{fav.title || "No Title"}</td>
                                <td>{fav.property.address || "No Address"}</td>
                                <td>
                                  {fav.property.beds || "No Beds"} /{" "}
                                  {fav.property.baths || "No Baths"}
                                </td>
                                <td>{fav.property.sqft || "No Sqft"}</td>
                                <td>${fav.property.price || "No Price"}</td>
                                <td>
                                  {fav.property.propertytype || "No Type"}
                                </td>
                                <td className="sorting_1">
                                  {new Date(
                                    fav.created_at
                                  ).toLocaleDateString() || "No Date"}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${
                                      fav.property.status === "Active"
                                        ? "badge-success"
                                        : "badge-secondary"
                                    }`}
                                  >
                                    {fav.property.status || "No Status"}
                                  </span>
                                </td>
                                <td>
                                  <a className="btn btn-primary m-1" href="#">
                                    <i className="fa fa-search "></i> View
                                  </a>
                                  <a className="btn btn-danger m-1" href="#">
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                  <a className="btn btn-success m-1" href="#">
                                    <i className="fa fa-share-square-o"></i>{" "}
                                    Share
                                  </a>
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              No saved properties found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="example_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {favourites.length} of {favourites.length}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="example_previous"
                        >
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="0"
                            tabindex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="1"
                            tabindex="0"
                            className="page-link"
                          >
                            1
                          </a>
                        </li>
                        <li
                          className="paginate_button page-item next disabled"
                          id="example_next"
                        >
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="2"
                            tabindex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "favoriteAgents":
        return (
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fa fa-heart-o " style={{ color: "#c70000" }}></i>{" "}
                Favorite Agents
              </h3>
            </div>
            <div className="card-body">
              <div
                id="example_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="example_length">
                      <label>
                        Show{" "}
                        <select
                          name="example_length"
                          aria-controls="example"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          fdprocessedid="v3qv5e"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div id="example_filter" className="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="example"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      id="example"
                      className="table table-striped table-bordered dataTable no-footer"
                      style={{ width: "100%" }}
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Thumbnail: activate to sort column ascending"
                            style={{ width: "83px" }}
                          >
                            Thumbnail
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Title: activate to sort column ascending"
                            style={{ width: "44px" }}
                          >
                            Title
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Address: activate to sort column ascending"
                            style={{ width: "141px" }}
                          >
                            Name
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Beds/Baths: activate to sort column ascending"
                            style={{ width: "86px" }}
                          >
                            City
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Sqft: activate to sort column ascending"
                            style={{ width: "32px" }}
                          >
                            About
                          </th>
                          {/* <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Price: activate to sort column ascending"
                            style={{ width: "38px" }}
                          >
                            Price
                          </th> */}
                          {/* <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Type: activate to sort column ascending"
                            style={{ width: "42px" }}
                          >
                            Type
                          </th> */}
                          <th
                            className="sorting_desc"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-sort="descending"
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "65px" }}
                          >
                            Date
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Status: activate to sort column ascending"
                            style={{ width: "47px" }}
                          >
                            Status
                          </th>
                          {/* <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="example"
                            rowspan="1"
                            colspan="1"
                            aria-label="Action: activate to sort column ascending"
                            style={{ width: "270px" }}
                          >
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(favourites) && favourites.length > 0 ? (
                          favourites
                            .filter((fav) => fav.agent) // Filter to include only items with property data
                            .map((fav, index) => (
                              <tr key={index} className="odd">
                                <td className="text-center">
                                  <img
                                    src={
                                      fav.agent.agentphoto ||
                                      "https://via.placeholder.com/100"
                                    }
                                    alt="Property Thumbnail"
                                    width="100px"
                                  />
                                </td>
                                <td>{fav.agent.title || "No Title"}</td>
                                <td>{fav.agent.name || "Sheikh Ali Ahmad"}</td>
                                <td>{fav.agent.city || "No Address"}</td>
                                {/* <td>
                                  {fav.property.beds || "No Beds"} /{" "}
                                  {fav.property.baths || "No Baths"}
                                </td> */}
                                <td>{fav.agent.about || "No Data"}</td>
                                {/* <td>${fav.property.price || "No Price"}</td>
                                <td>
                                  {fav.property.propertytype || "No Type"}
                                </td> */}
                                <td className="sorting_1">
                                  {new Date(
                                    fav.created_at
                                  ).toLocaleDateString() || "No Date"}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${
                                      fav.agent.status === "active"
                                        ? "badge-success"
                                        : "badge-secondary"
                                    }`}
                                  >
                                    {fav.agent.status || "No Status"}
                                  </span>
                                </td>
                                <td>
                                  <a className="btn btn-primary m-1" href="#">
                                    <i className="fa fa-search "></i> View
                                  </a>
                                  <a className="btn btn-danger m-1" href="#">
                                    <i className="fa fa-trash"></i> Delete
                                  </a>
                                  <a className="btn btn-success m-1" href="#">
                                    <i className="fa fa-share-square-o"></i>{" "}
                                    Share
                                  </a>
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              No saved properties found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="example_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {favourites.length} of {favourites.length}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="example_previous"
                        >
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="0"
                            tabindex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="1"
                            tabindex="0"
                            className="page-link"
                          >
                            1
                          </a>
                        </li>
                        <li
                          className="paginate_button page-item next disabled"
                          id="example_next"
                        >
                          <a
                            href="#"
                            aria-controls="example"
                            data-dt-idx="2"
                            tabindex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "saved-searches":
        return (
          <div>
            <h1>Saved Searches Content</h1>
            <p>Yeh Saved Searches ka content hai.</p>
          </div>
        );
      case "reviews":
        return (
          <div>
            <h1>My Reviews Content</h1>
            <p>Yeh My Reviews ka content hai.</p>
          </div>
        );
      case "account":
        return (
          <div>
          <figure className="col-xl-10 col-lg-10 col-md-9 col-sm-9 dash-right">
            <h1>Edit Account</h1>

            <div className="row">
              <div className="col-md-3 listbox">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">My Profile</h3>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <div className="userprofile ">
                        <div className="userpic  brround">
                          {" "}
                          <img
                            src="https://militaryhousingcenter.us/images/users/default.png"
                            alt=""
                            className="userpicimg"
                          />{" "}
                        </div>
                        <h3 className="username mb-2">aliahmadsheikh</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 customform">
                <form
                  id="updateaccount"
                  action="/editaccount.php"
                  method="post"
                  name="Form"
                  enctype="multipart/form-data"
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Edit Profile</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label className="" for="name">
                          Full Name{" "}
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          placeholder="for e.g. Maykel Morales"
                          value="aliahmadsheikh"
                          name="name"
                          type="text"
                          fdprocessedid="yp6sw4"
                        />
                      </div>

                      <div className="form-group">
                        <label className="" for="email">
                          Email{" "}
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          placeholder="for e.g. info@brokertoagent.com"
                          value="aliahmad11@gmail.com"
                          onkeyup="return restrict('email')"
                          name="email"
                          type="text"
                          fdprocessedid="qtl27j"
                        />
                      </div>

                      <div className="form-group">
                        <label for="username">Username </label>
                        <input
                          className="form-control"
                          id="username"
                          disabled="disabled"
                          placeholder="for e.g. maykelmorales"
                          value="aliahmadsheikh"
                          onkeyup=" return restrict('username')"
                          name="username"
                          type="text"
                          fdprocessedid="l4kqki"
                        />
                      </div>

                      <div className="form-group">
                        <label for="company">Company </label>
                        <input
                          className="form-control"
                          id="company"
                          placeholder=""
                          value=""
                          name="company"
                          type="text"
                          fdprocessedid="cpg0si"
                        />
                      </div>

                      <div className="form-group">
                        <label for="phone">Phone </label>
                        <input
                          className="form-control"
                          id="phone"
                          placeholder=""
                          value=""
                          name="phone"
                          type="text"
                          fdprocessedid="dlpshn"
                        />
                      </div>

                      <div className="form-group">
                        <label for="address">Address </label>
                        <textarea
                          className="form-control"
                          rows="3"
                          id="address"
                          name="address"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label for="city">City </label>
                        <input
                          className="form-control"
                          id="city"
                          placeholder=""
                          value=""
                          name="city"
                          type="text"
                          fdprocessedid="blqyij"
                        />
                      </div>

                      <div className="form-group">
                        <label for="state">State </label>
                        <input
                          className="form-control"
                          id="state"
                          placeholder=""
                          value=""
                          name="state"
                          type="text"
                          fdprocessedid="rdlagl"
                        />
                      </div>

                      <div className="form-group">
                        <label for="zipcode">Zipcode </label>
                        <input
                          className="form-control"
                          id="zipcode"
                          placeholder=""
                          value=""
                          name="zipcode"
                          type="text"
                          fdprocessedid="dknxuh"
                        />
                      </div>

                      <div className="form-group">
                        <label for="thumb">
                          Photo <br />
                          <small>
                            <small>(Width 100 px x 100 px Height)</small>
                          </small>
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="thumb"
                            accept=".png, .jpg, .jpeg"
                          />
                          <label className="custom-file-label" for="thumb">
                            Choose file
                          </label>
                          <br />
                          <div id="uploaded_image">
                            <img
                            alt=""
                              src="images/users/default.png"
                              style={{width:"60px",height:"60px"}}
                            />
                          </div>
                          <br />
                          <input
                            id="fileimage"
                            className="form-control"
                            value="default.png"
                            name="profilethumbnail"
                            type="hidden"
                            readonly="readonly"
                          />
                          <input
                            className="form-control"
                            value="default.png"
                            name="profilethumbnailold"
                            id="profilethumbnailold"
                            type="hidden"
                            readonly="readonly"
                          />
                        </div>
                      </div>

                      <div className="ln_solid"></div>
                      <div className="form-group">
                        <div className="col-md-6 col-md-offset-3">
                          <button
                            type="submit"
                            className="btn btn-success"
                            name="editprofile"
                            id="editprofile"
                            onclick="return Verify();"
                            fdprocessedid="5xy0ec"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </figure>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <article id="main-wrapper">
        <header id="main-header" className="sticky-top">
          <section className="header-top">
            <article className="container-fluid">
              <figure className="row top-bar">
                <figure className="col-md-12">
                  <nav className="top-menu term-head text-right">
                    <li>
                      <a href="/">
                        SAVED SEARCHES
                        <span className="badge topbadge themebg">4</span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        FAVORITES
                        <span className="badge topbadge themebg">0</span>
                      </a>
                    </li>

                    <li>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="hi-drop dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-user-circle-o"
                            aria-hidden="true"
                          ></i>
                          Hi {localStorage.getItem("userName")}
                        </button>
                        <div className="hi-drop dropdown-menu">
                          <a className="hi-drop dropdown-item" href="/">
                            Dashboard
                          </a>
                          <a className="hi-drop dropdown-item" href="/">
                            Edit Account
                          </a>
                          <a
                            className="hi-drop dropdown-item"
                            href="/"
                            onClick={() => {
                              localStorage.removeItem("token");
                            }}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </li>
                  </nav>

                  <nav className="main-menu term-head text-left">
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Find an Agent</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </nav>
                </figure>
              </figure>
              <figure className="row">
                <div className="bs-example" style={{ paddingLeft: 0 }}>
                  <figure className="top-logo">
                    <Link to="/">
                      <img alt="" src="images/logo.png" />
                    </Link>
                  </figure>
                  <nav
                    className="navbar navbar-light bg-light mobilenavbtn"
                    style={{
                      width: "20% !important",
                      display: "block",
                      textAlign: "right",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="fa fa-bars text-white"></span>
                    </button>
                  </nav>

                  <div
                    className="collapse navbar-collapse mobilenav"
                    id="navbarCollapse"
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/">Va Loan</a>
                    </li>
                    <li>
                      <a href="/">Relocation</a>
                    </li>
                    <li>
                      <a href="/">Resources</a>
                    </li>
                    <li>
                      <a href="/">Advertise</a>
                    </li>
                    <li>
                      <ContactModal />
                    </li>
                  </div>
                </div>

                <figure className="col-md-3 logoback">
                  <Link to="/">
                    <img alt="" className="logo" src="images/logo5.png" />
                  </Link>
                </figure>
                <figure className="offset-md-2 pt-3 col-md-7">
                  <div className="row justify-content-right searchadj">
                    <div className="col-md-12 text-right input-group md-form form-sm form-2 pl-0">
                      <input
                        className="form-control my-0 py-1 red-border"
                        type="text"
                        placeholder="Search by Military Installation, Address, City, County, Zip Code or MLS #"
                        aria-label="Search"
                        style={{ fontSize: "17px" }}
                      />
                      <div className="input-group-append searchbar">
                        <span
                          className="input-group-text red lighten-3"
                          id="basic-text1"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="label-icon">Both</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <ul className="category_filters">
                            <li>
                              <input
                                className="cat_type category-input"
                                data-label="All"
                                id="all"
                                value=""
                                name="radios"
                                type="radio"
                              />
                              <label for="all">For Sale</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="radios"
                                id="Design"
                                value="Design"
                              />
                              <label className="category-label" for="Design">
                                For Rent
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button
                        id="searchBtn"
                        type="button"
                        className="btn btn-secondary btn-search"
                      >
                        <span className="label-icon">SEARCH</span>
                      </button>
                    </div>
                  </div>
                </figure>
              </figure>
            </article>
          </section>
        </header>

        <article id="dashboard-section">
          <section className="container-fluid">
            <figure className="row">
              <figure className="col-xl-2 col-lg-2 col-md-3 col-sm-3 dash-left">
                <nav className="dash-menu">
                  <a href="#">
                    <li
                      className={activeTab === "dashboard" ? "active" : ""}
                      onClick={() => setActiveTab("dashboard")}
                    >
                      <img alt="" src="images/dashboard-icon-3.png" /> Dashboard
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "notifications" ? "active" : ""}
                      onClick={() => setActiveTab("notifications")}
                    >
                      <img alt="" src="images/dashboard-icon-2.png" />{" "}
                      Notifications
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "listings" ? "active" : ""}
                      onClick={() => setActiveTab("listings")}
                    >
                      <img alt="" src="images/dashboard-icon-3.png" /> My
                      Listings
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={
                        activeTab === "favoriteProperties" ? "active" : ""
                      }
                      onClick={() => setActiveTab("favoriteProperties")}
                    >
                      <img alt="" src="images/dashboard-icon-4.png" /> Favorite
                      Properties
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "favoriteAgents" ? "active" : ""}
                      onClick={() => setActiveTab("favoriteAgents")}
                    >
                      <img alt="" src="images/dashboard-icon-4.png" /> Favorite
                      Agents
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "saved-searches" ? "active" : ""}
                      onClick={() => setActiveTab("saved-searches")}
                    >
                      <img alt="" src="images/dashboard-icon-5.png" /> Saved
                      Searches
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "reviews" ? "active" : ""}
                      onClick={() => setActiveTab("reviews")}
                    >
                      <img alt="" src="images/dashboard-icon-6.png" /> My
                      Reviews
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className={activeTab === "account" ? "active" : ""}
                      onClick={() => setActiveTab("account")}
                    >
                      <img alt="" src="images/dashboard-icon-7.png" /> Account
                    </li>
                  </a>
                </nav>
              </figure>
              <figure className="col-xl-10 col-lg-10 col-md-9 col-sm-9 dash-right">
                {renderContent()}
              </figure>
            </figure>
          </section>
        </article>
      </article>
      <footer id="main-footer">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Useful Links</h6>
              <nav className="footer-menu">
                <li>
                  <Link to="/terms">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/policy">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/terms">Business Directory</Link>
                </li>
                <li>
                  <Link to="/fairHousing">Fair Housing</Link>
                </li>
                <li>
                  <Link to="/terms">About Us</Link>
                </li>
                <li>
                  <Link to="/terms">Careers</Link>
                </li>
                <li>
                  {/* <a href="#contact-popup" className="open-popup-link"> */}
                  <ContactModal />
                  {/* </a> */}
                </li>
              </nav>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <h6>Recent News</h6>
              <article className="address-info" alt="">
                <img src="images/news-img-1.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
              <article className="address-info">
                <img src="images/news-img-2.jpg" alt="" />
                <p>
                  <strong>Selling your property?</strong>
                </p>
                <p>
                  <span className="fa fa-calendar"></span> July 8, 2019
                </p>
                <p>
                  In todays compectitive market, there are many ways to sell...
                </p>
              </article>
            </figure>
            <figure className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-right">
              <h6>Follow Us</h6>
              <p>
                Keep up with us on social media for new listings, events and
                special promotions.
              </p>
              <p>
                <img src="images/social-icon-1.jpg" alt="" />
                <img src="images/social-icon-2.jpg" alt="" />
                <img src="images/social-icon-3.jpg" alt="" />
                <img src="images/social-icon-4.jpg" alt="" />
                <img src="images/social-icon-5.jpg" alt="" />
                <img src="images/social-icon-6.jpg" alt="" />
              </p>
            </figure>
          </figure>
        </section>
      </footer>
      <article id="foot-btm">
        <section className="container-fluid">
          <figure className="row">
            <figure className="col-md-12 footer-bottom">
              <p>
                MilitaryHousingCenter.com is NOT affiliated with the Dept. of
                Veterans Affairs or any government agency.
              </p>
              <p>© 2020 Military Housing Center, Inc. All Rights Reserved. </p>
            </figure>
          </figure>
        </section>
      </article>
    </div>
  );
}

export default Dashboard;
