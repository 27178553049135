import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import propertyData from "../utils/property.json";
import axios from "axios";

const AgentModal = ({ id = '' , email = '' }) => {
  const [modal, setModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();
  const [agentData, setAgentData] = useState([]);
  const location = useLocation();

  const [formType, setFormType] = useState("signin");
  const [activeTab, setActiveTab] = useState("overview");
  const tabTypes = [
    "overview",
    "about",
    "ratings",
    "service",
    "listing",
    "contact",
    "videos",
  ];

  useEffect(() => {
    if (id !== undefined) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get("profile-popup") === id.toString()) {
        setModal(true);
      } else {
        setModal(false);
      }
    }
  }, [location.search, id]);

  useEffect(() => {
    axios
      .get(`https://backend.tanzee.pk/api/agent/${id}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setAgentData(response.data);
        } else {
          setAgentData([response.data]);
        }
      })
      .catch((error) => {
        console.error("there is an error", error);
      });
  }, [id]);
  useEffect(() => {
    // Filter properties based on agentId
    const agentProperties = propertyData.filter(
      (property) => property.agentId === id
    );
    setProperties(agentProperties);
  }, [id]);

  const handleClick = (tab) => {
    setActiveTab(tab);
    setFormType(tab);
  };
  const closeModal = () => {
    navigate(location.pathname);
    setModal(false);
    setActiveTab("overview");
    setFormType("default");
  };
  const emailSend = () => {
    window.location.href = `mailto:${email}`;
  };
  const renderForm = () => {
    if (!Array.isArray(agentData)) {
      return <div>Error: Invalid data format</div>;
    }
    switch (formType) {
      case "overview":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <figure className="row" style={{ margin: 0 }}>
                              <figure
                                className="col-md-5 pro-left"
                                style={{ paddingLeft: "40px" }}
                              >
                                <h1>
                                  {data.name} <br />
                                  {data.company.name}
                                </h1>
                                <figure className="bor-btm"></figure>

                                <figure className="pro-info">
                                  <p>
                                    <img
                                      alt=""
                                      className="star"
                                      src="images/stars-img.png"
                                    />{" "}
                                    {data.reviews_count}
                                  </p>
                                  <p>
                                    <span>Company</span> : {data.company.name}
                                  </p>
                                  <p>
                                    <span>Title</span> : {data.title}
                                  </p>
                                  <p>
                                    <span>City</span> : {data.city}
                                  </p>
                                  <p>
                                    <span>State</span> : {data.state}
                                  </p>
                                  <p>
                                    <span>Phone</span> : {data.phone}
                                  </p>
                                  <p>
                                    <span>Email</span> : {data.email}
                                  </p>
                                  <p>
                                    <span>Hours</span> :{data.hours}
                                  </p>
                                </figure>

                                <div className="result-btn text-left">
                                  <p>
                                    <a
                                      className="read-more"
                                      href={data.website}
                                    >
                                      My Website
                                    </a>
                                  </p>
                                </div>

                                <div className="pro-so">
                                  <p>
                                    <a href={data.facebook}>
                                      {" "}
                                      <img alt="" src="images/fb.jpg" />
                                    </a>
                                    <a href={data.twitter}>
                                      {" "}
                                      <img alt="" src="images/tw.jpg" />
                                    </a>
                                    <a href={data.linkedin}>
                                      {" "}
                                      <img alt="" src="images/in.jpg" />
                                    </a>
                                  </p>
                                </div>
                              </figure>
                              <figure
                                className="col-md-7 pro-right"
                                style={{ paddingRight: "40px" }}
                              >
                                <img
                                  alt=""
                                  className="r-img"
                                  src={data.photo}
                                />
                              </figure>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "about":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active pro-tex"
                            id="nav-about"
                            role="tabpanel"
                            aria-labelledby="nav-about-tab"
                          >
                            {data.about}
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "ratings":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active pro-tex"
                            id="nav-rate"
                            role="tabpanel"
                            aria-labelledby="nav-rate-tab"
                          >
                            <figure className="pro-info profile-t">
                              <p>
                                <img
                                  alt=""
                                  className="star"
                                  src="images/stars-img.png"
                                />{" "}
                                {data.reviews_count}
                              </p>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "service":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>

                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane show fade active pro-tex"
                            id="nav-service"
                            role="tabpanel"
                            aria-labelledby="nav-service-tab"
                          >
                            {data.service_areas.map((service, index) => (
                              <figure
                                className="pro-info profile-t"
                                key={index}
                              >
                                <p>
                                  <span>Service</span> : {service.name}
                                </p>
                                <p>
                                  <span>City</span> : {service.city}
                                </p>
                                <p>
                                  <span>State</span> : {service.state}
                                </p>
                              </figure>
                            ))}
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "listing":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active pro-tex"
                            id="nav-listing"
                            role="tabpanel"
                            aria-labelledby="nav-listing-tab"
                          >
                            <figure className="row search-section tab-list">
                              {properties.map((card, i) => (
                                <article
                                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 propertygrid pb-3"
                                  key={i}
                                >
                                  <figure
                                    style={{
                                      backgroundImage: `url(${card.image})`,
                                      backgroundSize: "cover",
                                      padding: "10px 0px 0px 0px",
                                      position: "relative",
                                      minHeight: "350px",
                                      borderRadius: "3px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <figure className="info-top">
                                      <figure className="info-left">
                                        <p>{card.personDetail}</p>
                                      </figure>
                                      <figure className="info-right">
                                        <img
                                          alt=""
                                          src="images/heart-icon.png"
                                        />
                                      </figure>
                                    </figure>
                                    <figure className="result-info">
                                      <h5> {card.houseDetail} </h5>
                                      <h2>{card.price}</h2>
                                      <p>{card.description}</p>
                                      <nav className="top-menu">
                                        <li>
                                          <a href="/">
                                            <img
                                              alt=""
                                              src="images/bed-icon.png"
                                            />
                                            {card.bed}{" "}
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <img
                                              alt=""
                                              src="images/bath-icon.png"
                                            />{" "}
                                            {card.bath}{" "}
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <img
                                              alt=""
                                              src="images/area-icon.png"
                                            />
                                            {card.area}{" "}
                                          </a>
                                        </li>
                                      </nav>
                                    </figure>
                                  </figure>
                                  <figure className="bottom-logo">
                                    <img
                                      alt=""
                                      src="images/result-img-logo.png"
                                    />
                                    <p>
                                      <strong>Nearest Installation:</strong>
                                      <br />
                                      Joint Base Elmendorf Richardson 4.8 Miles
                                    </p>
                                  </figure>
                                </article>
                              ))}
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "contact":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active pro-tex"
                            id="nav-contact"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                          >
                            <figure className="pro-info profile-t">
                              <figure className="col-md-12 member-right">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <img alt="" src="images/name-icon.png" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name=""
                                    className="form-control"
                                    placeholder="Name"
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <img alt="" src="images/mail-icon.png" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name=""
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <img alt="" src="images/phone-icon.png" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name=""
                                    className="form-control"
                                    placeholder="Phone"
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <img
                                        alt=""
                                        className="help-img"
                                        src="images/help-icon.png"
                                      />
                                    </span>
                                  </div>
                                  <textarea
                                    type="textarea"
                                    name=""
                                    className="form-control"
                                    placeholder="How may I help you?"
                                  ></textarea>
                                </div>
                                <br />

                                <button
                                  type="submit"
                                  className="read-more"
                                  onClick={emailSend}
                                >
                                  <span className="glyphicon glyphicon-off"></span>
                                  <img alt="" src="images/send-icon.png" />
                                  SEND
                                </button>
                              </figure>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      case "videos":
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active pro-tex"
                            id="nav-video"
                            role="tabpanel"
                            aria-labelledby="nav-video-tab"
                          >
                            <figure className="row search-section tab-list">
                              {data.videos.map((video, index) => (
                                <article
                                  key={index}
                                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12"
                                >
                                  <iframe
                                    width="100%"
                                    height="300"
                                    src={video.youtubeurl}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={video.name}
                                  ></iframe>
                                </article>
                              ))}
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
      default:
        return (
          <>
            {agentData.map((data) => (
              <article id="profile-popup" key={data.id}>
                <article id="member-popup" className="pro-x">
                  <section className="container-fluid">
                    <figure className="row">
                      <div className="col-md-12 center pro-t">
                        <nav className="navbar-sticky">
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            {tabTypes.map((tab) => (
                              <a
                                key={tab}
                                className={`nav-item nav-link ${
                                  activeTab === tab ? "active" : ""
                                }`}
                                id={`nav-${tab}-tab`}
                                data-toggle="tab"
                                href={`#nav-${tab}`}
                                role="tab"
                                aria-controls={`nav-${tab}`}
                                aria-selected={activeTab === tab}
                                onClick={() => handleClick(tab)}
                              >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                              </a>
                            ))}
                          </div>
                          <button
                            onClick={closeModal}
                            className="mfp-close roundlink"
                            fdprocessedid="om5qql"
                          >
                            <span className="mfp-closeing">
                              <img
                                className="mfp-close reel-btn-off"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                              <img
                                className="mfp-close reel-btn-on"
                                src="images/x2-icon.png"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </span>
                          </button>
                        </nav>
                        <div
                          className="tab-content py-3 px-3 px-sm-0"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <figure className="row" style={{ margin: 0 }}>
                              <figure
                                className="col-md-5 pro-left"
                                style={{ paddingLeft: "40px" }}
                              >
                                <h1>
                                  {data.name} <br />
                                  {data.company.name}
                                </h1>
                                <figure className="bor-btm"></figure>

                                <figure className="pro-info">
                                  <p>
                                    <img
                                      alt=""
                                      className="star"
                                      src="images/stars-img.png"
                                    />{" "}
                                    {data.reviews_count}
                                  </p>
                                  <p>
                                    <span>Company</span> : {data.company.name}
                                  </p>
                                  <p>
                                    <span>Title</span> : {data.title}
                                  </p>
                                  <p>
                                    <span>City</span> : {data.city}
                                  </p>
                                  <p>
                                    <span>State</span> : {data.state}
                                  </p>
                                  <p>
                                    <span>Phone</span> : {data.phone}
                                  </p>
                                  <p>
                                    <span>Email</span> : {data.email}
                                  </p>
                                  <p>
                                    <span>Hours</span> :{data.hours}
                                  </p>
                                </figure>

                                <div className="result-btn text-left">
                                  <p>
                                    <a
                                      className="read-more"
                                      href={data.website}
                                    >
                                      My Website
                                    </a>
                                  </p>
                                </div>

                                <div className="pro-so">
                                  <p>
                                    <a href={data.facebook}>
                                      {" "}
                                      <img alt="" src="images/fb.jpg" />
                                    </a>
                                    <a href={data.twitter}>
                                      {" "}
                                      <img alt="" src="images/tw.jpg" />
                                    </a>
                                    <a href={data.linkedin}>
                                      {" "}
                                      <img alt="" src="images/in.jpg" />
                                    </a>
                                  </p>
                                </div>
                              </figure>
                              <figure
                                className="col-md-7 pro-right"
                                style={{ paddingRight: "40px" }}
                              >
                                <img
                                  alt=""
                                  className="r-img"
                                  src={data.photo}
                                />
                              </figure>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </section>
                </article>
              </article>
            ))}
          </>
        );
    }
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      {renderForm()}
    </Modal>
  );
};

export default AgentModal;
