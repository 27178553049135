import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DashBoard from "./pages/Dashboard";
import DashboardAdvertiser from "./pages/DashboardAdvertiser";
import DirectorySearchResult from "./pages/DirectorySearchResult";
import FairHousing from "./pages/FairHousing";
import Policy from "./pages/Policy";
import SearchResult from "./pages/SearchResult";
import Term from "./pages/Terms";
import AgentSearchResult from "./pages/AgentSearchResult";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalProvider } from "./modals/ModalContext";
import AuthModal from "./modals/AuthModal";
import FavModal from "./modals/FavModal";
function App() {
  return (
    <div>
      <ModalProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="dashboard" element={<DashBoard />} />
            <Route
              path="dashboardAdvertiser"
              element={<DashboardAdvertiser />}
            />
            <Route
              path="directorySearchResult"
              element={<DirectorySearchResult />}
            />
            <Route path="fairHousing" element={<FairHousing />} />
            <Route path="policy" element={<Policy />} />
            <Route path="SearchResult" element={<SearchResult />} />
            <Route path="terms" element={<Term />} />
            <Route path="AgentSearchResult" element={<AgentSearchResult />} />
            <Route element={<AuthModal />} />
            <Route element={<FavModal />} />
          </Routes>
        </Router>
      </ModalProvider>
    </div>
  );
}

export default App;
