import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ModalContext } from "./ModalContext";
import axios from "axios";

const FavModal = ({ prop_id, agentid }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { openAuthModal } = useContext(ModalContext);
  const [search, setSearch] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [uid, setUid] = useState("");
  const [favourites, setFavourites] = useState([]);
  const [favid, setFavid] = useState(null); // Store the favid

  useEffect(() => {
    const storedUid = localStorage.getItem("uid");
    if (storedUid) {
      setUid(storedUid);
      fetchFavourites(storedUid);
    }
  }, []);

  const fetchFavourites = (uid) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`https://backend.tanzee.pk/api/favourites/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setFavourites(response.data.favourites);
          checkIfSaved(response.data.favourites);
        })
        .catch((error) => {
          console.error("Error fetching favourites:", error);
        });
    }
  };

  const checkIfSaved = (favourites) => {
    const savedItem = favourites.find(
      (fav) =>
        fav.propid === parseInt(prop_id) || fav.agentid === parseInt(agentid)
    );

    if (savedItem) {
      setIsSaved(true);
      setFavid(savedItem.favid); // Store favid for the saved item
    } else {
      setIsSaved(false);
      setFavid(null); // Reset favid if not found
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");

    if (!search || search.length < 3) {
      alert("Please enter a valid search title (at least 3 characters).");
      return;
    }

    const saveData = {
      title: search,
      propid: parseInt(prop_id),
      requid: uid,
      agentid: parseInt(agentid),
    };

    if (token) {
      axios
        .post("https://backend.tanzee.pk/api/favourites", saveData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsSaved(true);
          setFavid(response.data.favid); // Store the newly saved favid
          handleClose();
          alert("Property saved successfully!");
        })
        .catch((error) => {
          console.error("Error saving property:", error.response.data);
          alert(`Failed to save property: ${error.response.data.message}`);
        });
    } else {
      handleClose();
      openAuthModal();

      const authModalInterval = setInterval(() => {
        const newToken = localStorage.getItem("token");
        const newUid = localStorage.getItem("uid");

        if (newToken && newUid) {
          clearInterval(authModalInterval);
          setUid(newUid);

          axios
            .post(
              "https://backend.tanzee.pk/api/favourites",
              { ...saveData, requid: newUid },
              {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
              }
            )
            .then((response) => {
              setIsSaved(true);
              setFavid(response.data.favid); // Store the newly saved favid
              alert("Property saved successfully after login!");
            })
            .catch((error) => {
              console.error("Error saving property:", error.response.data);
              alert(`Failed to save property: ${error.response.data.message}`);
            });
        }
      }, 1000);
    }
  };

  const handleUnsave = () => {
    const token = localStorage.getItem("token");
    if (token && favid) {
      // Remove from favourites via API
      axios
        .get(`https://backend.tanzee.pk/api/favourite/delete/${favid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsSaved(false);
          setFavid(null); // Clear the favid after unsaving
          alert("Property unsaved successfully!");
        })
        .catch((error) => {
          console.error("Error unsaving property:", error.response.data);
          alert(`Failed to unsave property: ${error.response.data.message}`);
        });
    } else {
      alert("You need to be logged in to unsave a property.");
    }
  };

  const renderForm = () => {
    return (
      <>
        <figure className="col-md-12 member-right">
          <img
            src="../images/x-icon.png"
            alt=""
            onClick={handleClose}
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              position: "absolute",
              zIndex: 1,
              right: 0,
              marginTop: "-25px",
              marginRight: "10px",
            }}
          />
          <h1>
            Saving <span>Property </span>
          </h1>
          <div className="forget-text">
            <p>Please enter your name of the search.</p>
          </div>
          <form
            id="savefavform"
            name="savefavform"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text fa fa-pencil"
                  style={{ paddingTop: "12px" }}
                ></span>
              </div>
              <input
                type="text"
                name="savesearchtitle"
                id="savesearchtitle"
                className="form-control"
                placeholder="Enter name of search"
                value={search}
                onChange={handleSearch}
              />
              <input
                type="hidden"
                name="saveproid"
                id="saveproid"
                className="form-control"
                value="19"
              />
            </div>
            <br />
            <button
              type="button"
              id="savesearhtitlebtnn"
              name="savesearhtitlebtnn"
              onClick={handleSave}
              className="read-more"
            >
              <span className="fa fa-heart"></span> &nbsp;&nbsp; Save{" "}
            </button>
          </form>
        </figure>
      </>
    );
  };

  return (
    <div>
      <>
        <img
          alt=""
          src={isSaved ? "images/heart-icon-red.png" : "images/heart-icon.png"}
          onClick={isSaved ? handleUnsave : handleShow}
          onHide={handleClose}
          closeButton="true"
        />
        <Modal show={show} closebutton="true" onHide={handleClose} size="md">
          {renderForm()}
        </Modal>
      </>
    </div>
  );
};

export default FavModal;
