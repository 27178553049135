import React, { useState } from "react";
import { Modal } from "reactstrap";


const ContactModal = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <a
        href="#contact-popup"
        className="open-popup-link"
        onClick={toggle}
        style={{ color: "white" }}
      >
        Contact Us
      </a>

      <Modal isOpen={modal} toggle={toggle} className={className} size="xl">
        <section className="container-fluid" style={{ position: "relative" }}>
          <figure className="row">
            <img
              src="../images/x-icon.png"
              alt=""
              onClick={toggle}
              style={{
                cursor: "pointer",
                width: "52px",
                height: "30px",
                position: "absolute",
                zIndex: 1,
                right: 0,
                marginTop: "20px",
              }}
            />
            <figure className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.351876839344!2d-110.97418748525445!3d32.22169068114322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d670e03638e7a7%3A0x182df555b908304f!2sRegus%20-%20Arizona%2C%20Tucson%20-%20Downtown%20Tucson!5e0!3m2!1sen!2s!4v1582118156181!5m2!1sen!2s"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                title="contact"
              ></iframe>
            </figure>
            <figure className="col-md-6 member-right p-5">
              <h1>
                <span>CONTACT </span>US{" "}
              </h1>
              <div className="forget-text">
                <p>
                  Thank you for your interest in MilitaryHousingCenter.com.
                  Please fill out the form below and one of our representatives
                  will contact you within one business day.
                </p>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img alt="" src="images/name-icon.png" />
                  </span>
                </div>
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <br />
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img alt="" src="images/mail-icon.png" />
                  </span>
                </div>
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <br />
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img alt="" src="images/phone-icon.png" />
                  </span>
                </div>
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Phone"
                />
              </div>
              <br />
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img
                      alt=""
                      className="help-img"
                      src="images/help-icon.png"
                    />
                  </span>
                </div>
                <textarea
                  type="textarea"
                  name=""
                  className="form-control"
                  placeholder="How may we help you?"
                ></textarea>
              </div>
              <br />

              <button type="submit" className="read-more">
                <span className="glyphicon glyphicon-off"></span>
                <img alt="" src="images/send-icon.png" />
                SEND
              </button>
            </figure>
          </figure>
        </section>
      </Modal>
    </>
  );
};

export default ContactModal;
