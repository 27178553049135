import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import FavModal from "../modals/FavModal";
function PropertyCard({
  setProgress,
  search,
  selected,
  minPrice,
  maxPrice,
  beds,
  baths,
  hoaFee,
  openHouse,
  threeD,
  largeDogs,
  cats,
  smallDogs,
  ac,
  laundry,
  pool,
  acceptsMhc,
  waterFront,
  onsiteParking,
  cityv,
  mountain,
  park,
  water,
  parkingSpots,
  haveGarage,
  basement,
  stories,
  mhcOwned,
  mhcDays,
  income,
  maxSquare,
  minSquare,
  minLotSize,
  maxLotSize,
  minYear,
  maxYear,
  keywords,
  selectedSort,
  onFilteredCountUpdate,
  visibleCount,
  setHasMore
}) {
  const [card, setCard] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(30);
    axios
      .get("https://backend.tanzee.pk/api/property/all")
      .then((response) => {
        setProgress(70);
        setCard(response.data);
        setHasMore(response.data.length > 4);
        setProgress(100);
      })
      .catch((error) => {
        console.log("there is an error", error);
      });
  }, []);
  useEffect(() => {
    setHasMore(visibleCount < card.length);
  }, [visibleCount, card]); 
  const filteredCards = useMemo(() => {
    const filtered = card.filter((data) => {
      
      const price = parseInt(data.price.replace(/[^\d]/g, ""), 10); // Assuming price is a string with $ and commas
      const withinPriceRange =
        (!minPrice || price >= minPrice) && (!maxPrice || price <= maxPrice);
      const searchLower = search.toLowerCase();

      let matchesSearch = false;

      if (search.includes(",")) {
        const [city, state] = search
          .split(",")
          .map((s) => s.trim().toLowerCase());
        const matchesCity = city
          ? data.city.toLowerCase().includes(city)
          : true;
        const matchesState = state
          ? data.state.toLowerCase().includes(state)
          : true;
        matchesSearch = matchesCity && matchesState;
      } else {
        matchesSearch =
          data.city.toLowerCase().includes(searchLower) ||
          data.state.toLowerCase().includes(searchLower) ||
          data.address.toLowerCase().includes(searchLower);
      }

      const matchesType = selected.includes(data.p_type);
      const matchesBeds = beds === "" || data.beds >= beds;
      const matchesBaths = baths === "" || data.baths >= baths;
      const matchesHoaFee =
        hoaFee === "" || parseInt(data.hoa, 10) >= parseInt(hoaFee, 10);
      const matchesOpenHouse = !openHouse || data.open === "yes";
      const matches3DHouse = !threeD || data.threed === "yes";
      const matchesLargeDogs = !largeDogs || data.largedog === "yes";
      const matchesCats = !cats || data.cats === "yes";
      const matchesSmallDogs = !smallDogs || data.smalldog === "yes";
      const matchesAc = !ac || data.ac === "yes";
      const matchesLaundry = !laundry || data.laundry === "yes";
      const matchesPool = !pool || data.pool === "yes";
      const matchesMhcApp = !acceptsMhc || data.mhcapp === "yes";
      const matchesWaterfront = !waterFront || data.waterfront === "yes";
      const matchesOnsiteParking = !onsiteParking || data.onsite === "yes";
      const matchesViewCity = !cityv || data.cityv === "yes";
      const matchesViewPark = !park || data.park === "yes";
      const matchesViewMountain = !mountain || data.mountain === "yes";
      const matchesViewWater = !water || data.water === "yes";

      const matchesParkingSpots =
        parkingSpots === "" ||
        parseInt(data.number_parking, 10) >= parseInt(parkingSpots, 10);
      const matchesGarage = !haveGarage || data.garage === "yes";
      const matchesBasement = !basement || data.basement === "yes";
      const matchesStories = !stories || data.stories === "single";
      const matchesMhcOwned = !mhcOwned || data.mhc_owned === "yes";
      const matchesMhcDays =
        mhcDays === "" || parseInt(data.mhc_days, 10) >= parseInt(mhcDays, 10);
      const matchesIncome = !income || data.income === "yes";
      const square_feet = parseInt(data.square_ft.replace(/[^\d]/g, ""), 10); // Assuming price is a string with $ and commas
      const matchesSquare =
        (!minSquare || square_feet >= minSquare) &&
        (!maxSquare || square_feet <= maxSquare);
      const lotSize = parseInt(data.lot_size.replace(/[^\d]/g, ""), 10); // Assuming price is a string with $ and commas
      const matchesLotSize =
        (!minLotSize || lotSize >= minLotSize) &&
        (!maxLotSize || lotSize <= maxLotSize);
      const yearBuilt = parseInt(data.year_built.replace(/[^\d]/g, ""), 10); // Assuming price is a string with $ and commas
      const matchesYearBuilt =
        (!minYear || yearBuilt >= minYear) &&
        (!maxYear || yearBuilt <= maxYear);
        const matchesKeywords = !keywords || data.keywords.toLowerCase().includes(keywords.toLowerCase());
        const matchesSort =
        selectedSort === "allrec" ||
        (selectedSort === "forrent" && data.forwhat === "rent") ||
        (selectedSort === "forsale" && data.forwhat === "sale");

     ;
      return (
        matchesSearch &&
        withinPriceRange &&
        matchesType &&
        matchesBeds &&
        matchesBaths &&
        matchesHoaFee &&
        matchesOpenHouse &&
        matches3DHouse &&
        matchesLargeDogs &&
        matchesCats &&
        matchesSmallDogs &&
        matchesAc &&
        matchesLaundry &&
        matchesPool &&
        matchesMhcApp &&
        matchesWaterfront &&
        matchesOnsiteParking &&
        matchesViewCity &&
        matchesViewMountain &&
        matchesViewPark &&
        matchesViewWater &&
        matchesParkingSpots &&
        matchesGarage &&
        matchesBasement &&
        matchesStories &&
        matchesMhcOwned &&
        matchesMhcDays &&
        matchesIncome &&
        matchesSquare &&
        matchesLotSize &&
        matchesYearBuilt &&
        matchesKeywords &&
        matchesSort
      );
    })
    // .sort((a, b) => {
    //   if (selectedSort === "forsale") {
    //     return a.forwhat === "sale" ? -1 : 1;
    //   } else if (selectedSort === "forrent") {
    //     return a.forwhat === "rent" ? -1 : 1;
    //   } else {
    //     return 0; // Show all properties when "all" is selected
    //   }
    // })
    onFilteredCountUpdate(filtered.length);
    return filtered;
  }, [
    card,
    search,
    selected,
    minPrice,
    maxPrice,
    beds,
    baths,
    hoaFee,
    openHouse,
    threeD,
    smallDogs,
    largeDogs,
    cats,
    ac,
    pool,
    laundry,
    onsiteParking,
    waterFront,
    acceptsMhc,
    mountain,
    water,
    park,
    cityv,
    parkingSpots,
    haveGarage,
    basement,
    stories,
    mhcOwned,
    mhcDays,
    income,
    minSquare,
    maxSquare,
    minLotSize,
    maxLotSize,
    minYear,
    maxYear,
    keywords,
    onFilteredCountUpdate,
    selectedSort
  ]);
  return (
    <>
       {filteredCards.slice(0, visibleCount).map((data) => (
        <article
          className="col-xl-3 col-lg-3 col-md-6 col-sm-6 propertygrid pb-3"
          key={data.prop_id}
        >
          <figure
            style={{
              backgroundImage: `url(${data.photo_prop})`,
              backgroundSize: "cover",
              padding: "10px 0px 0px 0px",
              position: "relative",
              minHeight: "350px",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            <figure className="info-top">
              <figure className="info-left">
                <p>{data.mhc_days}</p>
              </figure>
              <figure className="info-right">
                <FavModal prop_id={data.prop_id} />
              </figure>
            </figure>
            <figure className="result-info">
              <h5> House For {data.forwhat} </h5>
              <h2>$ {data.price}</h2>
              <p>
                {data.address}, {data.city}, {data.state}
              </p>
              <p style={{ display: "none" }}>{data.p_type}</p>
              <nav className="top-menu">
                <li>
                  <a href="/">
                    <img alt="" src="images/bed-icon.png" />
                    {data.beds} Beds{" "}
                  </a>
                </li>
                <li>
                  <a href="/">
                    <img alt="" src="images/bath-icon.png" /> {data.baths} Baths{" "}
                  </a>
                </li>
                <li>
                  <a href="/">
                    <img alt="" src="images/area-icon.png" />
                    {data.square_ft} Sqft{" "}
                  </a>
                </li>
              </nav>
            </figure>
          </figure>
          <figure
            className="bottom-logo"
            style={{ display: "flex", textAlign: "left" }}
          >
            <img alt="" src="images/result-img-logo.png" />
            <p style={{ fontSize: "13px" }}>
              <strong>Nearest Installation:</strong>
              <br />
              Joint Base Elmendorf Richardson 4.8 Miles
            </p>
            <div style={{ display: "none" }}>
              <p>{data.hoa}</p>

              <p>{data.number_parking}</p>
              <p>{data.garage}</p>
              <p>{data.lot_size}</p>
              <p>{data.year_built}</p>
              <p>{data.basement}</p>
              <p>{data.stories}</p>
              <p>{data.view}</p>
              <p>{data.mhc_owned}</p>
              <p>{data.keywords}</p>
              <p>{data.mhc_days}</p>
            </div>
          </figure>
        </article>
      ))}
    </>
  );
}

export default PropertyCard;
